import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AppContent from "./components/AppContent";
import FAQ from "./components/common/main-menu-components/FAQ";
import ContactUs from "./components/common/main-menu-components/ContactUs";
import TermsConditions from "./components/common/main-menu-components/TermsConditions";
import PrivacyPolicy from "./components/common/main-menu-components/PrivacyPolicy";
import CookiePolicy from "./components/common/main-menu-components/CookiePolicy";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buildingFullVisible: false,
      divStyle: { height: window.innerHeight, width: window.innerWidth }
    };
  }

  render() {
    const {
      buildings,
      error,
      toggleLike,
      selectBuilding,
      selectedBuilding,
      selectApartment,
      selectedApartment,
      mapCenter,
      selectedBuildingIndex,
      selectedBuildList,
      setMapCenter,
      likeApartment
    } = this.props;

    return (
      <div className="App-wrapper">
        {/* style={this.state.divStyle} */}
        <Router>
          <div className="App-content">
            <Switch>
              <Route
                exact
                path="/"
                render={props => (
                  <AppContent
                    {...props}
                    buildings={buildings}
                    error={error}
                    toggleLike={toggleLike}
                    selectBuilding={selectBuilding}
                    selectedBuilding={selectedBuilding}
                    selectedApartment={selectedApartment}
                    selectApartment={selectApartment}
                    mapCenter={mapCenter}
                    selectedBuildingIndex={selectedBuildingIndex}
                    selectedBuildList={selectedBuildList}
                    setMapCenter={setMapCenter}
                    likeApartment={likeApartment}
                    toggleFullVisibility={this.toggleFullVisibility}
                    buildingFullVisible={this.state.buildingFullVisible}
                  />
                )}
              />
              <Route path="/faq" component={FAQ} />
              <Route path="/contact" component={ContactUs} />
              <Route path="/terms-conditions" component={TermsConditions} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/cookie-policy" component={CookiePolicy} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }

  handleResize = e => {
    let windowheight = window.innerHeight;

    let divStyle = {
      height: windowheight,
      width: window.innerWidth
    };

    this.setState({ divStyle });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  toggleFullVisibility = () => {
    this.setState({
      buildingFullVisible: !this.state.buildingFullVisible
    });
  };
}

export default App;
