import React from "react";

const LocationIcon: React.FC = () => (
  <svg
    className="feed-comments__comment__commenter-order-number__location-icon"
    id="Object"
    height="12"
    viewBox="0 0 32 32"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#FFFFFF"
      d="m16 1a11 11 0 0 0 -11 11c0 5.81 9.8 18.1 10.22 18.63a1 1 0 0 0 1.56 0c.42-.53 10.22-12.82 10.22-18.63a11 11 0 0 0 -11-11zm0 14a4 4 0 1 1 4-4 4 4 0 0 1 -4 4z"
    />
  </svg>
);

export default LocationIcon;
