import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./Report.scss";
import ApartmentCounts from "./ApartmentCounts";
import LoadingExistingReportSkeleton from "./LoadingExistingReportSkeleton";
import {
  ArticlesOfAssociationApartments,
  ArticlesOfAssociationBaseInfo,
  ArticlesOfAssociationCommercialSpaces,
  BuildingWithConvertedAddresses,
  Comment
} from "../HouseMap/types";
import { DocumentAndFileName } from "./FilesDropBox";
import { submitReportDocuments } from "./Report.submitReportDocuments";
import { fetchExistingReport } from "./Report.fetchExistingReport";
import BuildingIconAndName from "./BuildingIconAndName";
import PlotOwnershipAndRedemptionClause from "./PlotOwnershipAndRedemptionClause";
import DocumentDropzone from "./DocumentDropzone";
import HeartIcon from "./Heart";
import { getCookie } from "../AppContent";
import ApartmentIcon from "./ApartmentIcon";
import CommercialSpaceIcon from "./CommercialSpace";
import { CSSTransition } from "react-transition-group";
import ExpanderArrow from "./ExpanderArrow";
import "./Comments.scss";
import SendIcon from "./SendIcon";
import BackIcon from "./BackIcon";
import DOMPurify from "dompurify";
import Linkify from "linkify-react";
import PageMetaTags from "./PageMetaTags";
import { getTimeAgo } from "./Report.helpers";
import CameraIcon from "./CameraIcon";
import TrashIcon from "./TrashIcon";
import ImageModal from "./ImageModal/ImageModal";
import ZoomIcon from "./ZoomIcon";
import LoadingSpinner from "../common/LoadingSpinner";
import TagManager from "react-gtm-module";
import { CloseIcon } from "../common/CloseIcon";

type Props = {
  selectedBuilding: BuildingWithConvertedAddresses;
  isLoggedIn: boolean;
  showImageModal: string | null;
  toggleReportVisible: () => void;
  setSelectedBuilding: (
    building: BuildingWithConvertedAddresses | null
  ) => void;
  setLoginModalVisible: (visible: boolean) => void;
  refreshFeed: () => void;
  setShowImageModal: (imageUrl: string | null) => void;
};

const Report = (props: Props) => {
  const {
    selectedBuilding,
    isLoggedIn,
    showImageModal,
    toggleReportVisible,
    setSelectedBuilding,
    setLoginModalVisible,
    refreshFeed,
    setShowImageModal
  } = props;
  const [isLoadingExistingReport, setIsLoadingExistingReport] = useState<
    boolean
  >(true);
  const [likeCount, setLikeCount] = useState<number>(0);
  const [userHasLiked, setUserHasLiked] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [analyzedFinancialStatement, setAnalyzedFinancialStatement] = useState<
    string | null
  >(null);
  const [
    analyzedMaintenanceNeedsAssessment,
    setAnalyzedMaintenanceNeedsAssessment
  ] = useState<string | null>(null);
  const [
    analyzedArticlesOfAssociationBaseInfo,
    setAnalyzedArticlesOfAssociationBaseInfo
  ] = useState<ArticlesOfAssociationBaseInfo | null>(null);
  const [
    analyzedArticlesOfAssociation,
    setAnalyzedArticlesOfAssociation
  ] = useState<string | null>(null);
  const [
    analyzedArticlesOfAssociationApartments,
    setAnalyzedArticlesOfAssociationApartments
  ] = useState<ArticlesOfAssociationApartments | null>(null);
  const [
    analyzedArticlesOfAssociationCommercialSpaces,
    setAnalyzedArticlesOfAssociationCommercialSpaces
  ] = useState<ArticlesOfAssociationCommercialSpaces | null>(null);
  const [
    apartmentsOwnedByTheHousingCompanyCount,
    setApartmentsOwnedByTheHousingCompanyCount
  ] = useState<number>(0);
  const [
    commercialSpacesOwnedByTheHousingCompanyCount,
    setCommercialSpacesOwnedByTheHousingCompanyCount
  ] = useState<number>(0);
  const [documentsAndFileNames, setDocumentsAndFileNames] = useState<
    DocumentAndFileName[]
  >([]);
  const [emptyReport, setEmptyReport] = useState<any>(null);
  const [isLoadingEmptyReport, setIsLoadingEmptyReport] = useState<boolean>(
    false
  );
  const [isAddingComment, setIsAddingComment] = useState<boolean>(false);
  const [isLoadingComments, setIsLoadingComments] = useState<boolean>(false);
  const [comments, setComments] = useState<Comment[]>([]);
  const [
    notificationsSubscriptionsToggle,
    setNotificationsSubscriptionsToggle
  ] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>("");
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>("");
  const commentFormRef = useRef<HTMLDivElement | null>(null);
  const [showPhotoButtons, setShowPhotoButtons] = useState<boolean>(false);
  const [imageDimensions, setImageDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const [addCommentFormHeightStyle, setAddCommentFormHeightStyle] = useState<
    string | null
  >("");
  const [photoPreviewBottomStyle, setPhotoPreviewBottomStyle] = useState<
    string | null
  >("");
  const [isSubmittingComment, setIsSubmittingComment] = useState<boolean>(
    false
  );

  const reportRef = useRef<HTMLDivElement | null>(null);
  const photoButtonsRef = useRef<HTMLDivElement>(null);
  const showPhotoButtonsButtonRef = useRef<HTMLButtonElement>(null);

  const currentUrl = window.location.href;
  const addressObject =
    selectedBuilding.addresses && selectedBuilding.addresses.length
      ? selectedBuilding.addresses[0]
      : selectedBuilding.properties &&
        selectedBuilding.properties.addresses &&
        selectedBuilding.properties.addresses.length
      ? selectedBuilding.properties.addresses[0]
      : "";
  const addressString =
    typeof addressObject === "string"
      ? "taloon"
      : `taloon osoitteessa ${addressObject.street} ${addressObject.houseNumber}, ${addressObject.city}`;
  const titleAddressString =
    typeof addressObject === "string"
      ? ""
      : `${addressObject.street} ${addressObject.houseNumber}, ${addressObject.city} `;

  const articlesOfAssociationPlaceHolderText =
    "Syötä taloyhtiön yhtiöjärjestys, saadaksesi analyysin";
  const financialStatementPlaceHolderText =
    "Syötä taloyhtiön tilinpäätös, tai talousarvio, saadaksesi analyysin";
  const maintenanceNeedsAssessmentPlaceHolderText =
    "Syötä taloyhtiön kunnossapitotarveselvitys, tai PTS -suunnitelma, saadaksesi analyysin";
  const [
    isHousingCompanyInfoExpanded,
    setIsHousingCompanyInfoExpanded
  ] = useState<boolean>(false);
  const [isCommentsExpanded, setIsCommentsExpanded] = useState<boolean>(true);

  const toggleHousingCompanyInfoExpansion = () => {
    setIsHousingCompanyInfoExpanded(prevState => !prevState);
  };

  const toggleCommentsExpansion = () => {
    setIsCommentsExpanded(prevState => !prevState);
  };

  const handleSubmitReportDocuments = () => {
    submitReportDocuments({
      selectedBuilding,
      documentsAndFileNames,
      setIsUploading,
      setAnalyzedArticlesOfAssociationBaseInfo,
      setAnalyzedArticlesOfAssociationApartments,
      setAnalyzedArticlesOfAssociationCommercialSpaces,
      setAnalyzedArticlesOfAssociation,
      setAnalyzedFinancialStatement,
      setAnalyzedMaintenanceNeedsAssessment
    });
  };

  const likeBuilding = () => {
    fetch(`${process.env.REACT_APP_API_URL}/like-building`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": getCookie("csrf_access_token")
      },
      body: JSON.stringify({
        like: !userHasLiked,
        selected_building: selectedBuilding
      })
    })
      .then(res => res.json())
      .then((data: { like_count: number; user_has_liked: boolean }) => {
        setLikeCount(data.like_count);
        setUserHasLiked(data.user_has_liked);
      })
      .catch(error => {
        setIsUploading(false);
        console.error("Error:", error);
      });
  };

  const fetchBuilding = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/building?id=${selectedBuilding.id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": getCookie("csrf_access_token")
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        // Check if data and properties are defined
        if (data && data.properties) {
          setLikeCount(data.properties.like_count || 0);
          setUserHasLiked(data.properties.user_has_liked || false);
        } else {
          console.warn("Building data or properties are undefined.");
          setLikeCount(0); // Set default value
          setUserHasLiked(false); // Set default value
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  const fetchComments = () => {
    setIsLoadingComments(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/comments?building-id=${selectedBuilding.id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": getCookie("csrf_access_token")
        }
      }
    )
      .then(res => res.json())
      .then(comments => {
        // Handle the building data as needed
        if (Array.isArray(comments)) {
          setComments(comments);
        }
        setIsLoadingComments(false);
      })
      .catch(error => {
        console.error("Error:", error);
        setIsLoadingComments(false);
      });
  };

  useEffect(() => {
    fetchExistingReport({
      selectedBuilding,
      setAnalyzedArticlesOfAssociationBaseInfo,
      setAnalyzedArticlesOfAssociation,
      setAnalyzedArticlesOfAssociationApartments,
      setAnalyzedArticlesOfAssociationCommercialSpaces,
      setAnalyzedFinancialStatement,
      setAnalyzedMaintenanceNeedsAssessment,
      setLikeCount,
      setUserHasLiked,
      setIsLoadingExistingReport,
      setEmptyReport,
      setIsLoadingEmptyReport
    });
    fetchBuilding();
    fetchComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuilding]);

  useEffect(() => {
    setCommercialSpacesOwnedByTheHousingCompanyCount(
      analyzedArticlesOfAssociationCommercialSpaces?.commercialSpaces.filter(
        space => space.isControlledDirectlyByTheHousingCompany
      ).length || 0
    );
  }, [analyzedArticlesOfAssociationCommercialSpaces]);

  useEffect(() => {
    setApartmentsOwnedByTheHousingCompanyCount(
      analyzedArticlesOfAssociationApartments?.apartments.filter(
        apartment => apartment.isControlledByTheHousingCompany
      ).length || 0
    );
  }, [analyzedArticlesOfAssociationApartments]);

  const handleShareButtonClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Tutustu mielenkiintoiseen taloon Jaakko.com:issa",
          text: `Tutustu ${addressString}. Osallistu keskusteluun, käy tykkäämässä, tai syötä taloyhtiön dokumentteja.`,
          url: currentUrl
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else if (navigator.clipboard && navigator.clipboard.writeText) {
      // Use the modern Clipboard API
      navigator.clipboard
        .writeText(currentUrl)
        .then(() => {
          alert("Linkki taloon kopioitu leikepöydälle.");
        })
        .catch(err => {
          console.error("Could not copy text: ", err);
          alert("Linkin kopiointi epäonnistui. Kopioi linkki manuaalisesti.");
        });
    } else {
      // Fallback approach for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = currentUrl;

      // Avoid scrolling to bottom
      textArea.style.position = "fixed";
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = "0";
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        if (successful) {
          alert("Linkki taloon kopioitu leikepöydälle.");
        } else {
          alert("Linkin kopiointi epäonnistui. Kopioi linkki manuaalisesti.");
        }
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
        alert("Linkin kopiointi epäonnistui. Kopioi linkki manuaalisesti.");
      }

      document.body.removeChild(textArea);
    }
  };

  const handleSubmitComment = () => {
    setIsSubmittingComment(true);
    TagManager.dataLayer({
      dataLayer: {
        event: "submit_comment_button_clicked",
        category: "User Interaction",
        action: "Click",
        label: "Submit Comment Button"
      }
    });
    // Create form data for multipart submission
    const formData = new FormData();

    formData.append("comment", commentText);
    formData.append("selected_building", JSON.stringify(selectedBuilding));

    // If an image file has been selected, append it
    if (selectedImageFile) {
      formData.append("image", selectedImageFile);
    }

    // Fire off the request
    fetch(`${process.env.REACT_APP_API_URL}/comment`, {
      method: "POST",
      credentials: "include",
      // Omit "Content-Type": "application/json" because fetch
      // will auto-set the correct boundary for multipart/form-data
      headers: {
        "X-CSRF-TOKEN": getCookie("csrf_access_token")
      },
      body: formData
    })
      .then(res => res.json())
      .then(data => {
        // Handle success, e.g. updating the comments list:
        setComments(prevComments => [
          ...prevComments,
          {
            _id: data.id,
            content: data.content,
            timestamp: data.timestamp,
            commenterOrderNumber: data.commenterOrderNumber,
            isAdminComment: data.isAdminComment,
            address: {
              country: selectedBuilding?.addresses?.[0]?.country || "",
              region: selectedBuilding?.addresses?.[0]?.region || "",
              city: selectedBuilding?.addresses?.[0]?.city || "",
              postcode: selectedBuilding?.addresses?.[0]?.postcode || "",
              street: selectedBuilding?.addresses?.[0]?.street || "",
              houseNumber: selectedBuilding?.addresses?.[0]?.houseNumber || "",
              coordinates: {
                lat: selectedBuilding?.addresses?.[0]?.coordinates?.lat || 0,
                lng: selectedBuilding?.addresses?.[0]?.coordinates?.lng || 0
              }
            },
            buildingId: selectedBuilding.id,
            images: data.images
          }
        ]);

        // Reset the comment input & the selected image
        setCommentText("");
        setSelectedImageFile(null);
        setImagePreviewUrl("");
        setIsAddingComment(false);
        setImageDimensions(null);

        // Scroll to the bottom of the report component
        if (reportRef.current) {
          reportRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end"
          });
          window.scrollTo({
            top: reportRef.current.offsetTop,
            behavior: "smooth"
          });
        }

        // Possibly refresh the feed or do any other updates
        refreshFeed();
        setIsSubmittingComment(false);
      })
      .catch(error => {
        console.error("Error:", error);
        setIsSubmittingComment(false);
      });
  };

  const handleToggleSubscribeToNotifications = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/toggle-subscribe-to-notifications`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": getCookie("csrf_access_token")
        },
        body: JSON.stringify({
          selected_building: selectedBuilding
        })
      }
    )
      .then(res => res.json())
      .then(data => {
        setNotificationsSubscriptionsToggle(data.is_subscribed);
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    if (isIOS) {
      document.body.classList.add("ios");
    } else {
      document.body.classList.remove("ios");
    }
  }, []);

  useLayoutEffect(() => {
    if (isAddingComment && commentFormRef.current) {
      commentFormRef.current.scrollIntoView();
    }
  }, [isAddingComment]);

  useEffect(() => {
    if (isLoggedIn) {
      fetch(
        `${process.env.REACT_APP_API_URL}/is-subscribed?building-id=${selectedBuilding.id}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": getCookie("csrf_access_token")
          }
        }
      )
        .then(res => res.json())
        .then(data => {
          setNotificationsSubscriptionsToggle(data.is_subscribed);
        })
        .catch(error => {
          console.error("Error:", error);
        });
    }
  }, [isLoggedIn, selectedBuilding]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImageFile(file);
      const previewUrl = URL.createObjectURL(file);
      setImagePreviewUrl(previewUrl);
      setShowPhotoButtons(false);

      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.height / img.width;
        const containerWidth = window.innerWidth; // Assuming full width
        const calculatedHeight = containerWidth * aspectRatio;
        setImageDimensions({ width: containerWidth, height: calculatedHeight });
      };
      img.src = previewUrl;
    }
  };

  const removeSelectedImage = () => {
    setSelectedImageFile(null);
    setImagePreviewUrl("");
    setImageDimensions(null);
  };

  const handleToggleShowPhotoButtons = () => {
    setShowPhotoButtons(!showPhotoButtons);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        photoButtonsRef.current &&
        !photoButtonsRef.current.contains(event.target as Node) &&
        showPhotoButtonsButtonRef.current &&
        !showPhotoButtonsButtonRef.current.contains(event.target as Node)
      ) {
        setShowPhotoButtons(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [photoButtonsRef, showPhotoButtonsButtonRef]);

  useEffect(() => {
    const updateStyle = () => {
      const width = window.innerWidth;
      if (imagePreviewUrl && width > 768) {
        setAddCommentFormHeightStyle("800px");
        setPhotoPreviewBottomStyle("90px");
      } else {
        setAddCommentFormHeightStyle(null);
        setPhotoPreviewBottomStyle(null);
      }
    };

    updateStyle(); // Set initial style
    window.addEventListener("resize", updateStyle);

    return () => {
      window.removeEventListener("resize", updateStyle);
    };
  }, [imagePreviewUrl]);

  useEffect(() => {
    // Scroll to the bottom of the report component after comments are updated
    if (reportRef.current) {
      const { offsetTop } = reportRef.current;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  }, [comments]); // Ensure this effect runs when comments change

  return (
    <div className="report-container">
      <PageMetaTags
        title={`Talosivu ${titleAddressString} - Jaakko.com`}
        description={`Tutustu kohteeseen ja jaa tietoa taloyhtiöstä.`}
        ogTitle="Tutustu mielenkiintoiseen taloon Jaakko.com:issa"
        ogDescription={`Tutustu ${addressString}. Osallistu keskusteluun, käy tykkäämässä, tai syötä taloyhtiön dokumentteja.`}
        ogImage={`https://www.jaakko.com/jaakko_logo.png`}
        ogUrl={currentUrl}
      />
      <div className="report" ref={reportRef}>
        <div className="report__close-wrapper">
          <button
            onClick={() => {
              toggleReportVisible();
              setSelectedBuilding(null);
            }}
            className="report__close-wrapper__close-button"
          >
            <CloseIcon size={20} />
          </button>
        </div>
        {isLoadingExistingReport ? (
          <LoadingExistingReportSkeleton />
        ) : (
          <>
            <div className="report__property-wrapper">
              <div className="report__controls">
                <div className="report__controls__notifications">
                  <label
                    className={`toggle-switch${
                      !isLoggedIn ? " toggle-switch--disabled" : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      checked={notificationsSubscriptionsToggle}
                      onChange={() => {
                        TagManager.dataLayer({
                          dataLayer: {
                            event: "building_subscribed",
                            category: "User Interaction",
                            action: "Click",
                            label: "Building Subscribed",
                            id: selectedBuilding.id,
                            name: selectedBuilding.properties.name
                          }
                        });
                        if (isLoggedIn) {
                          handleToggleSubscribeToNotifications();
                        } else {
                          setLoginModalVisible(true);
                        }
                      }}
                    />
                    <span className="slider" />
                  </label>
                  <div
                    className="report__controls__share-button__share-text"
                    onClick={() => {
                      TagManager.dataLayer({
                        dataLayer: {
                          event: "building_subscribed",
                          category: "User Interaction",
                          action: "Click",
                          label: "Building Subscribed",
                          id: selectedBuilding.id,
                          name: selectedBuilding.properties.name
                        }
                      });
                      if (isLoggedIn) {
                        handleToggleSubscribeToNotifications();
                      } else {
                        setLoginModalVisible(true);
                      }
                    }}
                  >
                    Seuraa
                  </div>
                </div>
                <button
                  className="report__controls__share-button"
                  onClick={() => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "building_shared",
                        category: "User Interaction",
                        action: "Click",
                        label: "Building Shared",
                        id: selectedBuilding.id,
                        name: selectedBuilding.properties.name
                      }
                    });
                    handleShareButtonClick();
                  }}
                >
                  <div className="report__controls__share-button__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0,0,256,256"
                      width="30px"
                      height="30px"
                      fillRule="nonzero"
                    >
                      <g
                        fill="#b7b7b7"
                        fillRule="nonzero"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="10"
                        strokeDasharray=""
                        strokeDashoffset="0"
                        fontFamily="none"
                        fontWeight="none"
                        fontSize="none"
                        textAnchor="none"
                        style={{ mixBlendMode: "normal" }}
                      >
                        <g transform="scale(8.53333,8.53333)">
                          <path d="M23,3c-2.20914,0 -4,1.79086 -4,4c0.00178,0.28117 0.03321,0.56136 0.09375,0.83594l-9.08203,4.54102c-0.75785,-0.87251 -1.85604,-1.3746 -3.01172,-1.37695c-2.20914,0 -4,1.79086 -4,4c0,2.20914 1.79086,4 4,4c1.15606,-0.0013 2.25501,-0.5027 3.01367,-1.375l9.07617,4.53906c-0.05923,0.27472 -0.08934,0.55491 -0.08984,0.83594c0,2.20914 1.79086,4 4,4c2.20914,0 4,-1.79086 4,-4c0,-2.20914 -1.79086,-4 -4,-4c-1.15606,0.0013 -2.25501,0.5027 -3.01367,1.375l-9.07617,-4.53906c0.05923,-0.27472 0.08934,-0.55491 0.08984,-0.83594c-0.00192,-0.28051 -0.03334,-0.56005 -0.09375,-0.83398l9.08203,-4.54102c0.75821,0.87178 1.85635,1.37313 3.01172,1.375c2.20914,0 4,-1.79086 4,-4c0,-2.20914 -1.79086,-4 -4,-4z"></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="report__controls__share-button__share-text">
                    Jaa
                  </div>
                </button>
                <button
                  disabled={isLoadingEmptyReport}
                  className="report__controls__like-button"
                  onClick={() => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "building_liked",
                        category: "User Interaction",
                        action: "Click",
                        label: "Building Liked",
                        id: selectedBuilding.id,
                        name: selectedBuilding.properties.name
                      }
                    });
                    if (isLoggedIn) {
                      likeBuilding();
                    } else {
                      setLoginModalVisible(true);
                    }
                  }}
                >
                  <div className="report__controls__like-button__icon-and-count">
                    {isLoadingEmptyReport ? (
                      <div className="report__controls__like-button__icon-and-count__loading-spinner">
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <>
                        <HeartIcon
                          className="report__controls__like-button__icon-and-count__icon"
                          fillColor={userHasLiked ? "#FF5E5E" : "#FF8E8E"}
                        />
                        <div
                          className={`report__controls__like-button__icon-and-count__like-count`}
                        >
                          {likeCount || 0}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="report__controls__like-button__like-text">
                    Tykkää
                  </div>
                </button>
              </div>
              <div className="report__property">
                <BuildingIconAndName
                  analyzedArticlesOfAssociationBaseInfo={
                    analyzedArticlesOfAssociationBaseInfo
                  }
                  selectedBuilding={selectedBuilding}
                />
                <PlotOwnershipAndRedemptionClause
                  analyzedArticlesOfAssociationBaseInfo={
                    analyzedArticlesOfAssociationBaseInfo
                  }
                />
              </div>
            </div>
            <div className="report__horizontal-line" />
            <div className="report__jaakko-wrapper">
              <div className="report__jaakko-head">
                <img src="/assets/jaakko_head.png" alt="Jaakko" height="64px" />
              </div>
              <div className="report__jaakko-text-wrapper">
                <div className="report__content__title">Jaakko</div>
                <div className="report__jaakko-talking">
                  {isLoadingEmptyReport || isLoadingExistingReport ? (
                    <span className="jaakko-talking-loading-dots">
                      <span></span>
                    </span>
                  ) : emptyReport && emptyReport.analyysi ? (
                    <>
                      <div className="report__jaakko-talking__text__first">
                        {`${emptyReport.analyysi}`}
                        <br />
                        <br />
                        Olen beta-versio, lähetäthän palautetta:{" "}
                        <a
                          className="report__jaakko-talking__link"
                          href="mailto:support@jaakko.com"
                        >
                          support@jaakko.com
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="report__jaakko-talking__text__first">
                        Alla taloyhtiön dokumentteihin perustuvia havaintojani
                        taloyhtiöstä. Mikäli tiedot ovat vajaat, voit ladata
                        lisää dokumentteja yllä.
                      </div>
                      <div className="report__jaakko-talking__text">
                        Olen beta-vaiheessa, joten tietoni ovat vielä rajalliset
                        ja saatan tehdä virheitä. Tarkistathan siis tärkeät
                        asiat aina vielä erikseen.
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="report__content__section__expander">
              <button
                onClick={toggleHousingCompanyInfoExpansion}
                className="report__content__section__expander__button"
              >
                Taloyhtiön tiedot
                <ExpanderArrow isExpanded={isHousingCompanyInfoExpanded} />
              </button>
            </div>
            <CSSTransition
              in={isHousingCompanyInfoExpanded}
              timeout={300}
              classNames="dropzone"
              unmountOnExit
            >
              <>
                <DocumentDropzone
                  documentsAndFileNames={documentsAndFileNames}
                  setDocumentsAndFileNames={setDocumentsAndFileNames}
                  handleSubmitReportDocuments={handleSubmitReportDocuments}
                  setLoginModalVisible={setLoginModalVisible}
                  isUploading={isUploading}
                  isLoggedIn={isLoggedIn}
                />
                <div className="report__content">
                  <div className="report__content__sub-section">
                    <div className="report__content__sub-title">Taloyhtiö</div>
                    <div
                      className={`report__info-box${
                        analyzedArticlesOfAssociation
                          ? ""
                          : " report__info-box--blurred"
                      }`}
                    >
                      <div className="report__info-box__rectangle report__info-box__rectangle--yellow" />
                      <div className="report__info-box__text">
                        {analyzedArticlesOfAssociation ||
                          articlesOfAssociationPlaceHolderText}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="report__horizontal-line" />
                <div className="report__owned-apartments-and-commercial-spaces-wrapper">
                  <div className="report__quantitative_content">
                    <ApartmentCounts
                      analyzedArticlesOfAssociationApartments={
                        analyzedArticlesOfAssociationApartments
                      }
                    />
                    <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company">
                      <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__title">
                        Taloyhtiön omistukset
                      </div>
                      <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__apartments-and-commercial-spaces">
                        <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__apartments">
                          <ApartmentIcon />
                          <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__apartments__count">
                            <span
                              className={`${
                                analyzedArticlesOfAssociationApartments
                                  ? ""
                                  : "report__apartments-and-commercial-spaces-owned-by-the-housing-company__apartments__count--blurred"
                              }`}
                            >
                              {analyzedArticlesOfAssociationApartments
                                ? apartmentsOwnedByTheHousingCompanyCount
                                : "-"}
                            </span>
                            <span>kpl</span>
                          </div>
                          <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__apartments__text">
                            Huoneistoja
                          </div>
                        </div>
                        <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__commercial-spaces">
                          <CommercialSpaceIcon />
                          <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__commercial-spaces__count">
                            <span
                              className={`${
                                analyzedArticlesOfAssociationApartments
                                  ? ""
                                  : "report__apartments-and-commercial-spaces-owned-by-the-housing-company__commercial-spaces__count--blurred"
                              }`}
                            >
                              {analyzedArticlesOfAssociationCommercialSpaces
                                ? commercialSpacesOwnedByTheHousingCompanyCount
                                : "-"}
                            </span>
                            <span>kpl</span>
                          </div>
                          <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__commercial-spaces__text">
                            Liiketiloja
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="report__horizontal-line" />
                <div className="report__content">
                  <div className="report__content__sub-section">
                    <div className="report__content__sub-title">Talous</div>
                    <div
                      className={`report__info-box${
                        analyzedFinancialStatement
                          ? ""
                          : " report__info-box--blurred"
                      }`}
                    >
                      <div className="report__info-box__rectangle report__info-box__rectangle--green" />
                      <div className="report__info-box__text">
                        {analyzedFinancialStatement ||
                          financialStatementPlaceHolderText}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="report__content">
                  <div className="report__content__sub-section">
                    <div className="report__content__sub-title">Remontit</div>
                    <div
                      className={`report__info-box${
                        analyzedMaintenanceNeedsAssessment
                          ? ""
                          : " report__info-box--blurred"
                      }`}
                    >
                      <div className="report__info-box__rectangle report__info-box__rectangle--red" />
                      <div className="report__info-box__text">
                        {analyzedMaintenanceNeedsAssessment ||
                          maintenanceNeedsAssessmentPlaceHolderText}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </CSSTransition>
            <div className="report__horizontal-line" />
            <div className="report__content__section__expander">
              <button
                onClick={toggleCommentsExpansion}
                className="report__content__section__expander__button"
              >
                Keskustelu
                <ExpanderArrow isExpanded={isCommentsExpanded} />
              </button>
            </div>
            <CSSTransition
              in={isCommentsExpanded}
              timeout={300}
              classNames="dropzone"
              unmountOnExit
            >
              <>
                <div className="comments">
                  {isLoadingComments ? (
                    <div className="comments__loading-spinner">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <>
                      {comments.length > 0 && !isAddingComment && (
                        <div className="comments__comments-list">
                          {comments.map(comment => {
                            const commenterOrderNumber =
                              comment.commenterOrderNumber;

                            return (
                              <div
                                className={`comments__comment comments__comment--${
                                  commenterOrderNumber <= 10
                                    ? commenterOrderNumber
                                    : "11"
                                }${
                                  comment.isAdminComment
                                    ? " comments__comment--jaakko-comment"
                                    : ""
                                }`}
                                key={comment._id}
                              >
                                <div className="comments__comment__commenter-order-number-and-timestamp-wrapper">
                                  <div className="comments__comment__commenter-order-number">
                                    {comment.isAdminComment ? (
                                      <div className="comments__comment__commenter-order-number__jaakko-comment">
                                        <img
                                          src="/naama5.png?v=1"
                                          alt="Jaakko"
                                          height="35px"
                                          className="comments__comment__commenter-order-number__jaakko-comment__jaakko-head"
                                        />
                                        <span> Jaakko</span>
                                      </div>
                                    ) : comment.commenterOrderNumber === 1 ? (
                                      "Eka."
                                    ) : (
                                      `${comment.commenterOrderNumber}.`
                                    )}
                                  </div>
                                  <div className="comments__comment__timestamp">
                                    {getTimeAgo(comment.timestamp)}
                                  </div>
                                </div>
                                <div className="comments__comment__content">
                                  {comment.images && comment.images.length > 0 && (
                                    <div
                                      className={`comments__comment__content__images${
                                        comment.content
                                          ? " comments__comment__content__images--with-text"
                                          : ""
                                      }`}
                                    >
                                      {comment.images.map((image, idx) => (
                                        <div
                                          key={idx}
                                          className="comments__comment__content__images__image-wrapper"
                                        >
                                          <img
                                            src={image.url}
                                            alt="Comment"
                                            loading="lazy"
                                            onClick={() =>
                                              setShowImageModal(image.url)
                                            }
                                          />
                                          <div className="comments__comment__content__images__image-wrapper__expand-button-wrapper">
                                            <button
                                              className="comments__comment__content__images__image-wrapper__expand-button-wrapper__button"
                                              onClick={() =>
                                                setShowImageModal(image.url)
                                              }
                                            >
                                              <ZoomIcon />
                                            </button>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                  <Linkify
                                    options={{
                                      attributes: {
                                        rel: "noopener noreferrer",
                                        target: "_blank"
                                      }
                                    }}
                                  >
                                    {DOMPurify.sanitize(comment.content)}
                                  </Linkify>
                                </div>
                              </div>
                            );
                          })}
                          {showImageModal && (
                            <ImageModal
                              closeImageModal={() => setShowImageModal(null)}
                              imageUrl={showImageModal}
                            />
                          )}
                        </div>
                      )}
                    </>
                  )}
                  {!comments.length && !isAddingComment && !isLoadingComments && (
                    <div className="comments__empty-state">
                      <div className="comments__empty-state__text">
                        <span>Haluatko kertoa tai kysyä talosta jotain?</span>
                        <span>Ole ensimmäinen anonyymi keskustelija! 🥳</span>
                      </div>
                    </div>
                  )}
                  {isAddingComment ? (
                    <>
                      <div
                        style={{
                          height:
                            imagePreviewUrl && addCommentFormHeightStyle
                              ? addCommentFormHeightStyle
                              : imageDimensions
                              ? `${imageDimensions.height + 336}px`
                              : "336px"
                        }}
                        className={`comments__add-comment-form`}
                        ref={commentFormRef}
                      >
                        <div className="comments__add-comment-form__textarea-wrapper">
                          <textarea
                            className="comments__add-comment-form__textarea-wrapper__textarea"
                            maxLength={250}
                            placeholder="Jaa kokemuksesi talosta, lisää kuva tai kysy jotain."
                            onChange={e => {
                              setCommentText(e.target.value);
                            }}
                          />
                        </div>
                        {selectedImageFile && (
                          <>
                            <div
                              style={{
                                bottom:
                                  imageDimensions && photoPreviewBottomStyle
                                    ? photoPreviewBottomStyle
                                    : imageDimensions
                                    ? `${126}px`
                                    : ""
                              }}
                              className="comments__add-comment-form__photo-preview"
                            >
                              <img src={imagePreviewUrl} alt="Preview" />
                            </div>
                            <div className="comments__add-comment-form__photo-preview__remove-button-wrapper">
                              <button
                                onClick={removeSelectedImage}
                                type="button"
                              >
                                <TrashIcon />
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                      <div
                        className={`comments__add-comment-form__photo-buttons-wrapper
                        ${
                          showPhotoButtons
                            ? " comments__add-comment-form__photo-buttons-wrapper--show"
                            : ""
                        }`}
                      >
                        <div
                          className="comments__add-comment-form__photo-buttons-wrapper__buttons"
                          ref={photoButtonsRef}
                        >
                          <label
                            htmlFor="upload-photo"
                            className="comments__add-comment-form__photo-buttons-wrapper__add-photo-button"
                          >
                            <input
                              type="file"
                              id="upload-photo"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                            Valitse kuva
                          </label>

                          <label
                            htmlFor="take-photo"
                            className="comments__add-comment-form__photo-buttons-wrapper__take-photo-button"
                          >
                            <input
                              type="file"
                              id="take-photo"
                              accept="image/*"
                              capture="environment"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                            Ota kuva
                          </label>
                        </div>
                      </div>
                      <div className="comments__add-comment-form__buttons">
                        <div className="comments__add-comment-form__buttons__photo-button">
                          <button
                            className={`comments__add-comment-form__buttons__photo-button__button${
                              showPhotoButtons
                                ? " comments__add-comment-form__buttons__photo-button__button--show-photo-buttons"
                                : ""
                            }`}
                            onClick={handleToggleShowPhotoButtons}
                            ref={showPhotoButtonsButtonRef}
                          >
                            <CameraIcon />
                          </button>
                        </div>
                        <div className="comments__send-or-return-comment-button-wrapper">
                          <button
                            className="comments__add-comment-form__back-button"
                            onClick={() => {
                              setIsAddingComment(false);
                              removeSelectedImage();
                              setImageDimensions(null);
                              setImagePreviewUrl(null);
                            }}
                          >
                            <span>Takaisin</span>
                            <BackIcon />
                          </button>
                          <button
                            className="comments__add-comment-form__send-button"
                            disabled={
                              (!commentText && !selectedImageFile) ||
                              isSubmittingComment
                            }
                            onClick={handleSubmitComment}
                          >
                            {isSubmittingComment ? (
                              <LoadingSpinner />
                            ) : (
                              <>
                                <span>Lähetä</span>
                                <SendIcon />
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      className={`comments__add-comment-button-wrapper${
                        comments.length === 0
                          ? " comments__add-comment-button-wrapper--no-comments"
                          : ""
                      }${
                        isLoadingComments
                          ? " comments__add-comment-button-wrapper--loading-comments"
                          : ""
                      }`}
                    >
                      <button
                        className="comments__add-comment-button-wrapper__button"
                        onClick={() => {
                          if (isLoggedIn) {
                            setIsAddingComment(true);
                          } else {
                            setLoginModalVisible(true);
                          }
                        }}
                      >
                        <div className="comments__add-comment-button-wrapper__button__plus-sign">
                          +
                        </div>
                      </button>
                    </div>
                  )}
                </div>
              </>
            </CSSTransition>
          </>
        )}
      </div>
    </div>
  );
};

export default Report;
