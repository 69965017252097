import React from "react";
import FilesDropBox, { DocumentAndFileName } from "./FilesDropBox";
import LoadingSpinner from "../common/LoadingSpinner";

type DocumentDropzoneProps = {
  documentsAndFileNames: DocumentAndFileName[];
  setDocumentsAndFileNames: (files: DocumentAndFileName[]) => void;
  handleSubmitReportDocuments: () => void;
  setLoginModalVisible: (visible: boolean) => void;
  isUploading: boolean;
  isLoggedIn: boolean;
};

const DocumentDropzone: React.FC<DocumentDropzoneProps> = ({
  documentsAndFileNames,
  setDocumentsAndFileNames,
  handleSubmitReportDocuments,
  setLoginModalVisible,
  isUploading,
  isLoggedIn
}) => {
  return (
    <div className="report__dropzone-wrapper">
      <div className="report__dropzone-content">
        <FilesDropBox
          documentsAndFileNames={documentsAndFileNames}
          setDocumentsAndFileNames={setDocumentsAndFileNames}
          isLoggedIn={isLoggedIn}
          setLoginModalVisible={setLoginModalVisible}
        />
        <div className="report__submit-button-wrapper">
          <div className="report__submit-button-wrapper__text">
            Lähettämällä dokumentteja sitoudut noudattamaan palveluidemme{" "}
            <a
              href="/terms-conditions"
              target="_blank"
              rel="noreferrer"
              className="report__submit-button-wrapper__text__link"
            >
              käyttöehtoja
            </a>
            , sekä ilmoitat lukeneesi ja hyväksyväsi Jaakkocom Oy:n{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="report__submit-button-wrapper__text__link"
            >
              yleisen tietosuojakuvauksen
            </a>
            .
          </div>
          {isUploading && (
            <div className="report__submit-button-wrapper__loading">
              <div className="report__submit-button-wrapper__loading-spinner">
                <LoadingSpinner />
              </div>
              <div>
                <div>Koostan raporttia tietojen pohjalta.</div>
                <div className="report__submit-button-wrapper__loading-text">
                  Tässä voi mennä muutama minuutti...
                </div>
              </div>
            </div>
          )}
          {!isUploading && (
            <button
              onClick={handleSubmitReportDocuments}
              className="report__submit-button-wrapper__submit-button"
              disabled={
                isUploading || documentsAndFileNames.length === 0 || !isLoggedIn
              }
            >
              {isUploading ? <LoadingSpinner /> : "Analysoi"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentDropzone;
