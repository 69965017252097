import { BuildingWithConvertedAddresses, POI } from "./types";
import TagManager from "react-gtm-module";

const getContext = (type: string, place: any) => {
  const context = place.context?.find((c: any) => c.id.startsWith(type));
  return context ? context.text : "Unknown";
};

export const performReverseGeocodingAndFetchPOIs = async (
  building: BuildingWithConvertedAddresses,
  selectBuilding: (building: BuildingWithConvertedAddresses) => void,
  lngLat: { lat: number; lng: number }
) => {
  // Now perform reverse geocoding
  const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${lngLat.lng},${lngLat.lat}.json?access_token=${accessToken}&types=address`
  )
    .then(response => response.json())
    .then(data => {
      if (data.features && data.features.length > 0) {
        const place = data.features[0];
        building.properties.name = place.place_name;

        TagManager.dataLayer({
          dataLayer: {
            event: "open_building_page",
            category: "User Interaction",
            action: "Click",
            label: "Building Clicked",
            id: building.id,
            name: building.properties.name,
            street: place.text || "Unknown",
            houseNumber: place.address || "Unknown",
            postcode: getContext("postcode", place),
            city: getContext("place", place),
            region: getContext("region", place),
            country: getContext("country", place)
          }
        });

        const address = {
          country: getContext("country", place),
          region: getContext("region", place),
          city: getContext("place", place),
          postcode: getContext("postcode", place),
          street: place.text || "Unknown",
          houseNumber: place.address || "Unknown",
          coordinates: { lat: lngLat.lat, lng: lngLat.lng }
        };

        building.properties.addresses.push(address);
        building.addresses.push(address);

        // Now fetch POIs using the Tilequery API
        const tilesetId = "mapbox.mapbox-streets-v8";
        const radius = 500; // in meters
        const layers =
          "poi_label,airport_label,transit_stop_label,ferry_aerialway_label,golf_hole_label,path_pedestrian_label,admin_0_boundary_disputed,admin_0_boundary,admin_1_boundary,admin_0_boundary_bg,aerialway,bridge_rail_tracks,bridge_rail,bridge_pedestrian,bridge_steps,bridge_path,bridge_pedestrian_case,bridge_steps_bg,bridge_path_bg,road_rail_tracks,road_rail,ferry_auto,ferry,golf_hole_line,road_pedestrian,road_steps,road_path,road_pedestrian_case,road_steps,road_path_bg"; // Layer containing POIs
        const limit = 15;

        fetch(
          `https://api.mapbox.com/v4/${tilesetId}/tilequery/${lngLat.lng},${lngLat.lat}.json?radius=${radius}&layers=${layers}&limit=${limit}&access_token=${accessToken}`
        )
          .then(response => response.json())
          .then(tilequeryData => {
            const pois: POI[] = tilequeryData.features.map((feature: any) => {
              const properties = feature.properties;
              return {
                id: feature.id.toString(),
                type: feature.type,
                properties: {
                  name: properties.name || properties.ref || "Unknown",
                  category: properties.class || properties.type || "Unknown",
                  layer: properties.tilequery.layer,
                  distanceInMeters: properties.tilequery.distance
                },
                geometry: feature.geometry
              };
            });

            // Add POIs to the building object
            building.pois = pois;

            // Now select the building with POIs
            selectBuilding(building);
          })
          .catch(error => {
            console.error("Error fetching POIs:", error);
            // Select the building even if POI fetching fails
            selectBuilding(building);
          });
      } else {
        console.log("No address found for this location");
      }
    })
    .catch(error => {
      console.error("Error with reverse geocoding request:", error);
    });
};
