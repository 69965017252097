import React, { useEffect, useState } from "react";
import "./SignupLoginModal.scss";
import GoogleIcon from "./GoogleIcon";
import { getCookie } from "../AppContent";
import TagManager from "react-gtm-module";
import { CloseIcon } from "../common/CloseIcon";
declare global {
  interface Window {
    google: any;
  }
}

type Props = {
  email: string;
  setShowLoginView: (showLoginView: boolean) => void;
  setShowSignupView: (showSignupView: boolean) => void;
  toggleSignupLoginModalVisible: () => void;
  validateEmail: (email: string) => string;
  onLoginSuccess: (expires_in: number) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setEmail: (email: string) => void;
};

const LoginOrSignup = (props: Props) => {
  const {
    email,
    setEmail,
    setShowLoginView,
    setShowSignupView,
    toggleSignupLoginModalVisible,
    validateEmail,
    onLoginSuccess,
    setIsLoggedIn
  } = props;
  const [isCheckingUser, setIsCheckingUser] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [googleOAuthClient, setGoogleOAuthClient] = useState(null);

  useEffect(() => {
    // Detect if browser is Edge
    /*     const isEdge = navigator.userAgent.indexOf("Edg") !== -1;
        setIsEdgeBrowser(isEdge); */

    /*     if (isEdge) {
     */ // Initialize OAuth 2.0 flow for Edge
    initializeOAuthFlow();
    /*     } else {
          // Use One Tap for other browsers
          initializeOneTapFlow();
        } */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*   const initializeOneTapFlow = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleSignIn
      });
    };
   */
  const initializeOAuthFlow = () => {
    const client = window.google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope: "openid email profile",
      ux_mode: "popup",
      callback: handleGoogleOAuthResponse
    });
    setGoogleOAuthClient(client);
  };

  /*   const handleGoogleSignIn = (response: any) => {
      console.log("Encoded JWT ID token: " + response.credential);
      // Send the token to your server for verification and login
      fetch(`${process.env.REACT_APP_API_URL}/verify-google-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": getCookie("csrf_access_token")
        },
        body: JSON.stringify({ token: response.credential }),
        credentials: "include"
      })
        .then(res => res.json())
        .then(data => {
          if (data.error) {
            console.error("Token verification failed:", data.error);
          } else {
            console.log("User verified:", data);
            setIsLoggedIn(true);
            toggleSignupLoginModalVisible();
            onLoginSuccess(data.expires_in);
          }
        })
        .catch(error => {
          console.error("Error:", error);
        });
    }; */

  const handleGoogleOAuthResponse = (response: any) => {
    console.log("OAuth response:", response);

    // "response.code" should be sent to your server so
    // that it can exchange the authorization code for
    // an access token and/or ID token.
    fetch(`${process.env.REACT_APP_API_URL}/verify-google-oauth-code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": getCookie("csrf_access_token")
      },
      body: JSON.stringify({ code: response.code }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) {
          console.error("Token verification failed:", data.error);
        } else {
          console.log("User verified:", data);
          setIsLoggedIn(true);
          toggleSignupLoginModalVisible();
          onLoginSuccess(data.expires_in);
        }
      })
      .catch(error => {
        console.error("Error verifying the OAuth code:", error);
      });
  };

  const handleSubmitCheckIfUserExists = async () => {
    setIsCheckingUser(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/check-if-user-exists?email=${email}`,
        {
          method: "GET"
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.exists) {
          setShowLoginView(true);
        } else {
          setShowSignupView(true);
        }
      } else {
        console.error("Email check failed");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsCheckingUser(false);
    }
  };

  const handleGoogleButtonClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "google_login_or_signup_button_clicked",
        category: "User Interaction",
        action: "Click",
        label: "Google Login or Signup Button"
      }
    });
    /* if (isEdgeBrowser) { */
    googleOAuthClient.requestCode();
    /*     } else {
          window.google.accounts.id.prompt();
        } */
  };

  return (
    <div className="signup-login-modal-overlay">
      <div className="signup-login-modal__info">
        <div className="signup-login-modal__info__title">
          <img src="/naama5.png" alt="Jaakko logo" width={50} height={50} />
          Aloita Jaakon käyttö
        </div>
        <div className="signup-login-modal__info__texts-wrapper">
          <div className="signup-login-modal__info__rectangle-and-text-wrapper">
            <div className="signup-login-modal__info__rectangle-and-text-wrapper__rectangle" />
            <div className="signup-login-modal__info__rectangle-and-text-wrapper__text">
              Keskustele mielenkiintoisista taloyhtiöistä ja seuraa niitä
            </div>
          </div>
          <div className="signup-login-modal__info__rectangle-and-text-wrapper">
            <div className="signup-login-modal__info__rectangle-and-text-wrapper__rectangle" />
            <div className="signup-login-modal__info__rectangle-and-text-wrapper__text">
              Pysy ajan tasalla omasta talostasi
            </div>
          </div>
          <div className="signup-login-modal__info__rectangle-and-text-wrapper">
            <div className="signup-login-modal__info__rectangle-and-text-wrapper__rectangle" />
            <div className="signup-login-modal__info__rectangle-and-text-wrapper__text">
              Saat sähköpostin seuraamiesi talojen tapahtumista
            </div>
          </div>
        </div>
        <div className="signup-login-modal__info__image-wrapper ">
          <img
            src="/assets/cellphones.png"
            alt="Jaakko app on mobile"
            width={262}
          />
        </div>
      </div>
      <div
        className="signup-login-modal signup-login-modal--login"
        onClick={e => e.stopPropagation()}
      >
        <div className="signup-login-modal__close-wrapper">
          <button
            onClick={toggleSignupLoginModalVisible}
            className="signup-login-modal__close-wrapper__close-button"
          >
            <CloseIcon size={20} />
          </button>
        </div>
        <div className="signup-login-modal__content">
          <div className="signup-login-modal__content__title">
            Luo tunnus tai kirjaudu
          </div>
        </div>
        <button
          id="custom-google-signin-button"
          className="custom-google-signin-button"
          onClick={handleGoogleButtonClick}
        >
          <div className="custom-google-signin-button__icon">
            <GoogleIcon />
          </div>
          <div className="custom-google-signin-button__text">
            Jatka Googlella
          </div>
          <div className="custom-google-signin-button__filler" />
        </button>
        <div className="signup-login-modal__content__or">
          <hr />
          tai jatka sähköpostilla
          <hr />
        </div>
        <form
          className="signup-login-modal__content__form"
          onSubmit={e => {
            e.preventDefault();
            const emailValidationError = validateEmail(email);
            setEmailError(emailValidationError);
            if (!emailValidationError) {
              handleSubmitCheckIfUserExists();
            }
          }}
        >
          <input
            type="email"
            placeholder="Sähköposti"
            className="signup-login-modal__content__input"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
            autoComplete="email"
          />
          {emailError && (
            <div className="signup-login-modal__content__error">
              {emailError}
            </div>
          )}
          <button
            type="submit"
            className="signup-login-modal__content__button signup-login-modal__content__button--login"
            disabled={isCheckingUser}
          >
            {isCheckingUser ? (
              <span className="loading-dots">
                <span></span>
              </span>
            ) : (
              "Seuraava"
            )}
          </button>
          <div className="signup-login-modal__content__text">
            Rekisteröitymällä luot Jaakko-tunnuksen ja sitoudut noudattamaan
            palveluidemme{" "}
            <a
              href="/terms-conditions"
              target="_blank"
              rel="noreferrer"
              className="signup-login-modal__content__text__link"
            >
              käyttöehtoja
            </a>
            , sekä ilmoitat lukeneesi ja hyväksyväsi Jaakkocom Oy:n{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="signup-login-modal__content__text__link"
            >
              yleisen tietosuojakuvauksen
            </a>
            .
          </div>
        </form>
        <div className="signup-login-modal__info-mobile">
          <div className="signup-login-modal__info-mobile__title">
            <img src="/naama5.png" alt="Jaakko logo" width={50} height={50} />
            Aloita Jaakon käyttö
          </div>
          <div className="signup-login-modal__info-mobile__texts-wrapper">
            <div className="signup-login-modal__info-mobile__rectangle-and-text-wrapper">
              <div className="signup-login-modal__info-mobile__rectangle-and-text-wrapper__rectangle" />
              <div className="signup-login-modal__info-mobile__rectangle-and-text-wrapper__text">
                Keskustele mielenkiintoisista taloyhtiöistä ja seuraa niitä
              </div>
            </div>
            <div className="signup-login-modal__info-mobile__rectangle-and-text-wrapper">
              <div className="signup-login-modal__info-mobile__rectangle-and-text-wrapper__rectangle" />
              <div className="signup-login-modal__info-mobile__rectangle-and-text-wrapper__text">
                Pysy ajan tasalla omasta talostasi
              </div>
            </div>
            <div className="signup-login-modal__info-mobile__rectangle-and-text-wrapper">
              <div className="signup-login-modal__info-mobile__rectangle-and-text-wrapper__rectangle" />
              <div className="signup-login-modal__info-mobile__rectangle-and-text-wrapper__text">
                Saat sähköpostin seuraamiesi talojen tapahtumista
              </div>
            </div>
          </div>
          <div className="signup-login-modal__info-mobile__image-wrapper ">
            <img
              src="/assets/cellphones.png"
              alt="Jaakko app on mobile"
              width={262}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginOrSignup;
