import React from "react";
import "./SignupLogin.scss";
import UserIcon from "./UserIcon";

type SignupLoginProps = {
  toggleLoginModal: () => void;
  isLoggedIn: boolean;
};

const SignupLogin = ({ toggleLoginModal, isLoggedIn }: SignupLoginProps) => (
  <div className="signup-login" onClick={toggleLoginModal}>
    <UserIcon />
    <div className={`signup-login__text`}>
      {isLoggedIn ? (
        <>
          <span>Kirjautunut</span> <span></span>
        </>
      ) : (
        <span>Kirjaudu</span>
      )}
    </div>
  </div>
);

export default SignupLogin;
