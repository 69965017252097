import React from "react";
import PlotOwnershipIcon from "./PlotOwnershipIcon";
import RedemptionClauseIcon from "./RedemptionClauseIcon";
import { ArticlesOfAssociationBaseInfo } from "../HouseMap/types";

type Props = {
  analyzedArticlesOfAssociationBaseInfo: ArticlesOfAssociationBaseInfo | null;
};

const PlotOwnershipAndRedemptionClause: React.FC<Props> = ({
  analyzedArticlesOfAssociationBaseInfo
}) => {
  const plotOwnership =
    analyzedArticlesOfAssociationBaseInfo?.ownership_of_the_plot === "rented"
      ? "Vuokra"
      : "Oma";
  const hasApartmentRedemptionClause = analyzedArticlesOfAssociationBaseInfo?.housing_company_or_shareholders_has_apartment_redemption_clause
    ? "Kyllä"
    : "Ei";

  return (
    <div className="report__property__plot-ownership-and-redemption-clause-wrapper">
      <div className="report__property__plot-ownership-wrapper">
        <div className="report__property__plot-ownership">
          <PlotOwnershipIcon
            plotIsOwn={
              !analyzedArticlesOfAssociationBaseInfo
                ? null
                : plotOwnership === "Oma"
                ? "yes"
                : "no"
            }
          />
        </div>
        <div className="report__property__additional-info">
          <div className="report__property__general-info__title">Tontti</div>
          <span
            className={`report__property__general-info__name${
              analyzedArticlesOfAssociationBaseInfo
                ? ""
                : " report__property__general-info__name--blurred"
            }`}
          >
            {analyzedArticlesOfAssociationBaseInfo ? plotOwnership : "-"}
          </span>
        </div>
      </div>
      <div className="report__property__redemption-clause-wrapper">
        <div className="report__property__redemption-clause">
          <RedemptionClauseIcon
            hasRedemptionClause={
              !analyzedArticlesOfAssociationBaseInfo
                ? null
                : analyzedArticlesOfAssociationBaseInfo.housing_company_or_shareholders_has_apartment_redemption_clause
                ? "no"
                : "yes"
            }
          />
        </div>
        <div className="report__property__additional-info">
          <div className="report__property__general-info__title">
            Lunastuspykälä
          </div>
          <span
            className={`report__property__general-info__name${
              analyzedArticlesOfAssociationBaseInfo
                ? ""
                : " report__property__general-info__name--blurred"
            }`}
          >
            {analyzedArticlesOfAssociationBaseInfo
              ? hasApartmentRedemptionClause
              : "-"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PlotOwnershipAndRedemptionClause;
