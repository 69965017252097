import React, { useEffect, useRef, useState } from "react";
import mapboxgl, { MapMouseEvent } from "mapbox-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import { BuildingWithConvertedAddresses, LatLng } from "./types";
import { getCookie } from "../AppContent";
import "./HouseMap.scss";
import { performReverseGeocodingAndFetchPOIs } from "./BuildingMap.helpers";
import LoadingSpinner from "../common/LoadingSpinner";
import RightArrow from "../RightArrow";
import TagManager from "react-gtm-module";
interface MapBoxExampleProps {
  mapCenter: LatLng;
  selectedBuilding: BuildingWithConvertedAddresses | null;
  triggeredFromSearch: boolean;
  feedIsOpen: boolean;
  mobileFeedIsOpen: boolean;
  setShowFeed: (showFeed: boolean) => void;
  setTriggeredFromSearch: (triggeredFromSearch: boolean) => void;
  selectBuilding: (building: BuildingWithConvertedAddresses) => void;
}

const BuildingMap = ({
  mapCenter,
  triggeredFromSearch,
  selectedBuilding,
  feedIsOpen,
  mobileFeedIsOpen,
  setShowFeed,
  selectBuilding,
  setTriggeredFromSearch
}: MapBoxExampleProps) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const speechBubbleRef = useRef<HTMLDivElement>(null);
  const jaakkoInfoRef = useRef<HTMLDivElement>(null);

  const prevSelectedBuildingRef = useRef<BuildingWithConvertedAddresses | null>(
    null
  );

  const [showZoomHint, setShowZoomHint] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("showZoomHint");
    return storedValue === null ? true : storedValue === "true";
  });

  const [showClickBuildingHint, setShowClickBuildingHint] = useState<boolean>(
    () => {
      const storedValue = localStorage.getItem("showClickBuildingHint");
      return storedValue === null ? true : storedValue === "true";
    }
  );

  const [showClickJaakkoHint, setShowClickJaakkoHint] = useState<boolean>(
    () => {
      const storedValue = localStorage.getItem("showClickJaakkoHint");
      return storedValue === null ? true : storedValue === "true";
    }
  );

  const [showJaakkoSpeechBubble, setShowJaakkoSpeechBubble] = useState(false);
  const [zoom, setZoom] = useState(15.5);

  const animationProgressRef = useRef(0);
  let animationFrameId = useRef<number>();

  const [isIframeLoading, setIsIframeLoading] = useState(true);

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setVh();

    window.addEventListener("resize", setVh);

    return () => {
      window.removeEventListener("resize", setVh);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        speechBubbleRef.current &&
        !speechBubbleRef.current.contains(event.target as Node) &&
        jaakkoInfoRef.current &&
        !jaakkoInfoRef.current.contains(event.target as Node)
      ) {
        setShowJaakkoSpeechBubble(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [speechBubbleRef, jaakkoInfoRef]);

  // Initialize the map only once
  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current!,
      style: `mapbox://styles/jaakkocom/${process.env.REACT_APP_MAPBOX_STYLE_ID}`,
      center: [mapCenter.lng, mapCenter.lat],
      zoom: 15.5
    });

    mapRef.current.on("load", () => {
      // Change cursor to pointer when hovering over a building
      mapRef.current!.on("mouseenter", "building", () => {
        mapRef.current!.getCanvas().style.cursor = "pointer";
      });

      mapRef.current!.on("mouseleave", "building", () => {
        mapRef.current!.getCanvas().style.cursor = "";
      });

      mapRef.current!.on("click", (e: MapMouseEvent) =>
        handleOnClickBuildingShowReport(e, mapRef, selectBuilding)
      );

      // Start the animation when the layer is ready
      mapRef.current!.on("sourcedata", () => {
        if (
          mapRef.current?.getLayer("building-jaakko-outline") &&
          !animationFrameId.current
        ) {
          // Start the animation
          animateGlow();
        }
      });
    });

    setTimeout(() => {
      if (zoom === 15.5) {
        setZoom(18);
      }
    }, 3000);

    // Return cleanup for unmount or re-mount
    return () => {
      // 1) Cancel any ongoing animation request
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
        animationFrameId.current = undefined;
      }

      // 2) Remove the map instance
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs only once

  // Update the map center smoothly when mapCenter changes
  useEffect(() => {
    if (!mapRef.current) return;

    if (triggeredFromSearch) {
      mapRef.current.flyTo({
        center: [mapCenter.lng, mapCenter.lat],
        essential: true,
        zoom
      });
      setTriggeredFromSearch(false);
    } else {
      mapRef.current.flyTo({
        center: [mapCenter.lng, mapCenter.lat],
        essential: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapCenter]);

  useEffect(() => {
    if (prevSelectedBuildingRef.current && !selectedBuilding) {
      fetchBuildingsWithinBounds(mapRef);
    }
    prevSelectedBuildingRef.current = selectedBuilding;
  }, [selectedBuilding]);

  useEffect(() => {
    fetchBuildingsWithinBounds(mapRef);

    // Add event listener for 'moveend' to fetch buildings when map view changes
    mapRef.current!.on("moveend", () => {
      fetchBuildingsWithinBounds(mapRef);
    });
  }, []);

  // Function to animate the glow effect
  const animateGlow = () => {
    if (!mapRef.current) {
      return;
    }

    if (!mapRef.current.getLayer("building-jaakko-outline")) {
      return;
    }

    // Increase the animation's progress
    animationProgressRef.current += 0.02;

    // Example lineWidth logic
    const lineWidth =
      1.4 + 1.4 * Math.abs(Math.sin(animationProgressRef.current));
    mapRef.current.setPaintProperty(
      "building-jaakko-outline",
      "line-width",
      lineWidth
    );

    const colorIntensity =
      60 + 40 * Math.abs(Math.sin(animationProgressRef.current));
    const color = `hsl(47, 92%, ${colorIntensity}%)`;
    mapRef.current.setPaintProperty(
      "building-jaakko-outline",
      "line-color",
      color
    );

    // Request next frame
    animationFrameId.current = requestAnimationFrame(animateGlow);
  };

  const hideZoomHint = () => {
    setShowZoomHint(false);
    localStorage.setItem("showZoomHint", "false");
  };

  const hideClickBuildingHint = () => {
    setShowClickBuildingHint(false);
    localStorage.setItem("showClickBuildingHint", "false");
  };

  const hideClickJaakkoHint = () => {
    setShowClickJaakkoHint(false);
    localStorage.setItem("showClickJaakkoHint", "false");
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, [feedIsOpen, mobileFeedIsOpen]);

  return (
    <div
      className={`map-wrapper ${feedIsOpen ? "map-wrapper--feed-open" : ""}`}
    >
      <>
        <div className="map-hints">
          {showClickBuildingHint && (
            <div className="click-building-hint">
              Klikkaa mitä tahansa rakennusta
              <img
                src="/assets/close.svg"
                alt="Close"
                height="22px"
                onClick={hideClickBuildingHint}
              />
            </div>
          )}
          {showZoomHint && (
            <div className="zoom-in-hint">
              Zoomaa nähdäksesi talonumerot
              <img
                src="/assets/close.svg"
                alt="Close"
                height="22px"
                onClick={hideZoomHint}
              />
            </div>
          )}
          {showClickJaakkoHint && (
            <div className="click-jaakko-hint">
              Klikkaa Jaakkoa
              <img
                src="/assets/close.svg"
                alt="Close"
                height="22px"
                onClick={hideClickJaakkoHint}
              />
            </div>
          )}
          {showJaakkoSpeechBubble && (
            <div className="jaakko-speech-bubble" ref={speechBubbleRef}>
              <iframe
                title="vimeo-player"
                src="https://player.vimeo.com/video/1044684402?h=3b4ee47dad"
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen
                // When the iframe finishes loading, mark it loaded
                onLoad={() => setIsIframeLoading(false)}
                style={{ display: isIframeLoading ? "none" : "block" }}
              ></iframe>

              {/* Conditionally show your loading indicator */}
              {isIframeLoading && (
                <div className="loading-indicator">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          )}
          <div
            className="jaakko-info"
            onClick={() => {
              setShowJaakkoSpeechBubble(!showJaakkoSpeechBubble);
              TagManager.dataLayer({
                dataLayer: {
                  event: "jaakko_info_clicked",
                  category: "User Interaction",
                  action: "Click",
                  label: "Jaakko Info Clicked"
                }
              });
            }}
            ref={jaakkoInfoRef}
          >
            <img
              src="/assets/info_jaakko.png"
              alt="Jaakko info"
              height="60px"
            />
          </div>
        </div>
      </>

      {!feedIsOpen && (
        <button
          className="map-feed-vertical-button"
          onClick={() => setShowFeed(true)}
        >
          <div className="map-feed-vertical-button__text">
            F<br />i<br />i<br />d<br />i
          </div>
          <div className="map-feed-vertical-button__icon">
            <RightArrow />
          </div>
        </button>
      )}

      <div
        ref={mapContainerRef}
        id="map-container"
        className="map-container"
      ></div>
    </div>
  );
};

const handleOnClickBuildingShowReport = (
  e: MapMouseEvent,
  mapRef: React.MutableRefObject<mapboxgl.Map | null>,
  selectBuilding: (building: BuildingWithConvertedAddresses) => void
) => {
  const buildingFeatures = mapRef.current!.queryRenderedFeatures(e.point, {
    layers: ["building"] // Ensure this matches your building layer name
  });

  if (buildingFeatures.length > 0) {
    const buildingFeature = buildingFeatures[0];

    const building: BuildingWithConvertedAddresses = {
      id: buildingFeature.id.toString(),
      centroid: { lat: e.lngLat.lat, lng: e.lngLat.lng },
      addresses: [],
      geometry: { type: "Polygon", coordinates: [] },
      properties: { name: "", addresses: [] },
      pois: []
    };

    // Get building outline coordinates
    if (buildingFeature.geometry.type === "Polygon") {
      building.geometry.coordinates = buildingFeature.geometry.coordinates;
    }

    performReverseGeocodingAndFetchPOIs(building, selectBuilding, e.lngLat);
  } else {
    console.log("No building found at this location");
  }
};

const fetchBuildingsWithinBounds = (
  mapRef: React.MutableRefObject<mapboxgl.Map | null>
) => {
  const bounds = mapRef.current!.getBounds();
  const bbox = {
    sw: [bounds.getWest(), bounds.getSouth()], // Southwest corner
    ne: [bounds.getEast(), bounds.getNorth()] // Northeast corner
  };

  fetch(`${process.env.REACT_APP_API_URL}/buildings-in-bounds`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": getCookie("csrf_access_token")
    },
    credentials: "include",
    body: JSON.stringify(bbox)
  })
    .then(response => response.json())
    .then(data => {
      // Process data to add "isRecentlyCommented" property
      const now = new Date();
      const oneDayInMs = 24 * 60 * 60 * 1000;

      data.features.forEach(feature => {
        const lastCommented = feature.properties.lastCommented;
        if (lastCommented) {
          const lastCommentedDate = new Date(lastCommented);
          const timeDiff = now.getTime() - lastCommentedDate.getTime();
          feature.properties.isRecentlyCommented = timeDiff <= oneDayInMs;
        } else {
          feature.properties.isRecentlyCommented = false;
        }
      });

      if (mapRef.current!.getSource("clicked-buildings")) {
        // Update existing source data
        (mapRef.current!.getSource(
          "clicked-buildings"
        ) as mapboxgl.GeoJSONSource).setData(data);
      } else {
        // Add new source and layers
        mapRef.current!.addSource("clicked-buildings", {
          type: "geojson",
          data: data
        });
        mapRef.current!.addLayer({
          id: "clicked-buildings-layer",
          type: "fill",
          source: "clicked-buildings",
          filter: ["==", ["get", "has_report"], true],
          paint: {
            // "fill-color": "#3E55CD",
            // "fill-color": "#051FA2",
            // "fill-color": "#FF5E5E",
            // "fill-color": "#F7CF3E",
            // "fill-color": "#F8C100",
            //  "fill-color": "#14B685",
            // "fill-color": "#8868E5",
            // "fill-color": "#6340C7",
            "fill-color": "#8095FF",
            // "fill-color": "#018860",
            "fill-opacity": 0.7
          }
        });
        // Add a line layer for the outline
        mapRef.current!.addLayer({
          id: "clicked-buildings-outline",
          type: "line",
          source: "clicked-buildings",
          filter: ["==", ["get", "has_report"], true],
          paint: {
            "line-color": "#F9F9F9", // White color for the outline
            "line-width": 0.5, // Adjust the width as needed
            "line-opacity": 0.6
          }
        });

        // After the 'clicked-buildings-outline' layer, add the heart icons and like counts
        mapRef.current!.loadImage(
          `${process.env.PUBLIC_URL}/heart.png`,
          (error, image) => {
            if (error) {
              console.error("Error loading heart icon:", error);
              return;
            }
            // Add the image to the map's style
            if (!mapRef.current!.hasImage("heart-icon")) {
              mapRef.current!.addImage("heart-icon", image!);
            }

            // Add a symbol layer to display the heart icon and like count on buildings with like_count > 0
            mapRef.current!.addLayer({
              id: "building-like-icons",
              type: "symbol",
              source: "clicked-buildings",
              filter: ["all", [">", ["get", "like_count"], 0]],
              minzoom: 1,
              layout: {
                "icon-image": "heart-icon",
                "icon-size": 0.6,
                "icon-offset": [-15, -16.5],
                "icon-allow-overlap": true,
                "text-field": ["get", "like_count"],
                "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
                "text-size": 12,
                "text-offset": [-0.75, -1.4],
                "text-anchor": "top",
                "text-allow-overlap": true
              },
              paint: {
                "text-color": "#FFF"
              }
            });
          }
        );

        // After the 'clicked-buildings-outline' layer, add the comment icons and comment counts
        mapRef.current!.loadImage(
          `${process.env.PUBLIC_URL}/chata.png`,
          (error, image) => {
            if (error) {
              console.error("Error loading comment icon:", error);
              return;
            }
            // Add the image to the map's style
            if (!mapRef.current!.hasImage("comment-icon")) {
              mapRef.current!.addImage("comment-icon", image!);
            }

            // Add a symbol layer to display the comment icon and comment count on buildings with commentsCount > 0
            mapRef.current!.addLayer({
              id: "building-comment-icons",
              type: "symbol",
              source: "clicked-buildings",
              filter: [
                "any",
                [
                  "all",
                  [">", ["get", "commentsCount"], 0],
                  ["!=", ["get", "admin_comment_flag"], true]
                ],
                [
                  "all",
                  [">", ["get", "commentsCount"], 1],
                  ["==", ["get", "admin_comment_flag"], true]
                ]
              ],
              minzoom: 1,
              layout: {
                "icon-image": "comment-icon",
                "icon-size": 0.8,
                "icon-offset": [15, -10.5],
                "icon-allow-overlap": true,
                "text-field": ["get", "commentsCount"],
                "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
                "text-size": 12,
                "text-offset": [1, -1.4],
                "text-anchor": "top",
                "text-allow-overlap": true
              },
              paint: {
                "text-color": "#202125"
              }
            });
          }
        );

        // Add the outline layer before adding the icon layer
        mapRef.current!.addLayer({
          id: "building-jaakko-outline",
          type: "line",
          source: "clicked-buildings",
          filter: ["==", ["get", "isRecentlyCommented"], true],
          minzoom: 1,
          paint: {
            "line-color": "#F7CF3E", // Gold color for the outline
            "line-width": 1.4, // Adjust the width as needed
            "line-opacity": 0.8
          }
        });

        // Load and add the Jaakko icon image
        mapRef.current!.loadImage(
          `${process.env.PUBLIC_URL}/assets/jaakko_head.png`,
          (error, image) => {
            if (error) {
              console.error("Error loading Jaakko icon:", error);
              return;
            }
            if (!mapRef.current!.hasImage("jaakko-icon")) {
              mapRef.current!.addImage("jaakko-icon", image!);
            }

            // Add the Jaakko icon layer on top of the outline layer
            mapRef.current!.addLayer({
              id: "building-jaakko-emojis",
              type: "symbol",
              source: "clicked-buildings",
              filter: ["==", ["get", "admin_comment_flag"], true],
              minzoom: 1,
              layout: {
                "icon-image": "jaakko-icon",
                "icon-size": 0.4,
                "icon-offset": [0, 40],
                "icon-allow-overlap": true
              }
            });
          }
        );
      }
    })
    .catch(error => {
      console.error("Error fetching buildings:", error);
    });
};

export default BuildingMap;
