import React, { useState, useRef, useEffect } from "react";
import "./SignupLoginModal.scss";
import TagManager from "react-gtm-module";

type Props = {
  email: string;
  setEmail: (email: string) => void;
  setShowSignupView: (showSignupView: boolean) => void;
  setShowLoginView: (showLoginView: boolean) => void;
  validatePassword: (password: string) => string;
  setShowEmailVerificationCodeInputView: (
    showEmailVerificationCodeInputView: boolean
  ) => void;
  setLoginError: (error: string) => void;
  validateEmail: (email: string) => string;
};

const Signup = (props: Props) => {
  const {
    email,
    setEmail,
    setShowSignupView,
    setShowLoginView,
    validatePassword,
    setShowEmailVerificationCodeInputView,
    setLoginError,
    validateEmail
  } = props;
  const [password, setPassword] = useState("");
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [passwordError, setPasswordError] = useState(""); // Add this line
  const [emailError, setEmailError] = useState(""); // Add this line

  // Add these references
  const signupEmailInputRef = useRef<HTMLInputElement>(null);
  const signupPasswordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (signupPasswordInputRef.current) {
      signupPasswordInputRef.current.focus();
    }
  }, []);

  const handleSubmitSignup = async () => {
    setIsSigningUp(true);
    TagManager.dataLayer({
      dataLayer: {
        event: "signup_button_clicked",
        category: "User Interaction",
        action: "Click",
        label: "Sign Up Button"
      }
    });
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email, password })
      });

      if (response.ok) {
        // Handle successful response
        setShowSignupView(false);
        setPassword("");
        setShowEmailVerificationCodeInputView(true);
      } else {
        const errorData = await response.json();
        if (errorData.error === "user_exists") {
          setShowSignupView(false);
          setPassword("");
          setShowLoginView(true);
          setLoginError(
            "Näyttää siltä, että sinulla on jo tunnus. Kirjaudu sisään."
          );
        } else {
          console.error("Signup failed");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSigningUp(false);
    }
  };

  return (
    <>
      <div className="signup-login-modal__content">
        <div className="signup-login-modal__content__title">Luo tunnus</div>
      </div>
      <form
        className="signup-login-modal__content__form"
        onSubmit={e => {
          e.preventDefault();
          const emailValidationError = validateEmail(email);
          setEmailError(emailValidationError); // Validate email on submit
          if (!emailValidationError) {
            handleSubmitSignup();
          }
        }}
      >
        <input
          type="email"
          placeholder="Sähköposti"
          className="signup-login-modal__content__input"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
          }}
          required // Add this line
          ref={signupEmailInputRef} // Add this line
        />
        {emailError && (
          <div className="signup-login-modal__content__error">{emailError}</div>
        )}
        <input
          type="password"
          placeholder="Salasana"
          className="signup-login-modal__content__input"
          value={password}
          onChange={e => {
            setPassword(e.target.value);
            setPasswordError(validatePassword(e.target.value)); // Add this line
          }}
          required // Add this line
          ref={signupPasswordInputRef} // Add this line
        />
        {passwordError && (
          <div className="signup-login-modal__content__error">
            {passwordError}
          </div>
        )}
        <button
          className="signup-login-modal__content__button"
          disabled={isSigningUp || passwordError !== "" || emailError !== ""}
          type="submit"
        >
          {isSigningUp ? (
            <span className="loading-dots">
              <span></span>
            </span>
          ) : (
            "Seuraava"
          )}
        </button>
      </form>
    </>
  );
};

export default Signup;
