import React from "react";

const MapIcon = () => (
  <svg
    height="18"
    viewBox="0 0 32 32"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Layer_52" data-name="Layer 52">
      <path
        fill="#fff"
        d="m20 25.22-8 1.6v-20l8-1.6zm-10-18.5-6.76-1.72a1 1 0 0 0 -.86.18 1 1 0 0 0 -.38.82v18a1 1 0 0 0 .76 1l7.24 1.78zm19.24.28-7.24-1.78v20.06l6.76 1.72a1 1 0 0 0 .24 0 1 1 0 0 0 1-1v-18a1 1 0 0 0 -.76-1z"
      />
    </g>
  </svg>
);

export default MapIcon;
