import React from "react";
import "./components.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import MainMenu from "../../menu/MainMenu";
import "./PolicyPage.scss";

const CookiePolicy = () => {
  const history = useHistory();
  return (
    <Container className="p-0 policy-page-container">
      <MainMenu isMainMenuPage />
      <div className="closeMenuView" onClick={() => history.push("/")} />
      <div className="policy-page-content">
        <Link to="/">
          <div className="flatsonBlueText">Jaakko.com</div>
        </Link>
        <Row>
          <p className="page-name">Jaakkocom oy evästeet</p>
        </Row>
        <Col>
          <p className="small-text">
            <b>Voimaantulopäivä:</b> 09 joulukuuta 2024
            <br />
            <br />
            Käytämme evästeitä parantaaksemme käyttökokemustasi
            verkkosivustollamme <a href="https://jaakko.com">jaakko.com</a>.
            Tämä evästekäytäntö on osa Jaakkocom Oy:n tietosuojakäytäntöä. Se
            kattaa evästeiden käytön laitteesi ja sivustomme välillä.
            <br />
            <br />
            Annamme myös perustietoa käyttämistämme kolmansien osapuolten
            palveluista, jotka saattavat myös käyttää evästeitä osana
            palveluaan. Tämä käytäntö ei kata niiden evästeitä.
            <br />
            <br />
            Jos et halua hyväksyä meiltä tulevia evästeitä, sinun on
            ohjeistettava selaimesi hylkäämään https://jaakko.com-evästeet.
            Tällöin emme ehkä pysty tarjoamaan sinulle joitakin haluamiasi
            sisältöjä ja palveluja.
          </p>

          <p className="small-text">
            <b className="paragraph-title">Mikä on eväste?</b>
            <br />
            Eväste on pieni tieto informaatiota, jonka verkkosivusto tallentaa
            laitteellesi, kun vierailet sivustolla. Se sisältää yleensä tietoja
            itse verkkosivustosta, yksilöllisen tunnisteen, jonka avulla sivusto
            voi tunnistaa selaimesi, kun palaat, lisätietoja, jotka palvelevat
            evästeen tarkoitusta, ja itse evästeen käyttöiän.
            <br />
            <br />
            Evästeitä käytetään tiettyjen ominaisuuksien mahdollistamiseen
            (esim. kirjautuminen), sivuston käytön seurantaan (esim.
            analytiikka), käyttäjäasetusten tallentamiseen (esim. aikavyöhyke,
            ilmoitusasetukset) ja sisällön personointiin (esim. mainonta,
            kieli).
            <br />
            <br />
            Käyttämäsi verkkosivuston asettamia evästeitä kutsutaan yleensä
            ensimmäisen osapuolen evästeiksi. Niillä seurataan yleensä vain
            toimintaasi kyseisellä sivustolla.
            <br />
            <br />
            Muiden sivustojen ja yritysten (eli kolmansien osapuolten) asettamia
            evästeitä kutsutaan kolmannen osapuolen evästeiksi. Niiden avulla
            sinua voidaan seurata muilla sivustoilla, jotka käyttävät samaa
            kolmannen osapuolen palvelua.
          </p>

          <p className="small-text">
            <b className="paragraph-title">Evästetyypit ja niiden käyttö</b>
          </p>

          <p className="small-text">
            <b>Suorituskykyevästeet</b>
            <br />
            Suorituskykyevästeet seuraavat, miten käytät verkkosivustoa
            vierailusi aikana. Yleensä nämä tiedot ovat anonyymejä ja koottuja,
            ja niitä seurataan kaikkien sivuston käyttäjien osalta. Ne auttavat
            yrityksiä ymmärtämään kävijöiden käyttötottumuksia, tunnistamaan ja
            diagnosoimaan ongelmia tai virheitä, joita käyttäjät saattavat
            kohdata, ja tekemään parempia strategisia päätöksiä yleisönsä
            yleisen verkkosivustokokemuksen parantamiseksi. Nämä evästeet voivat
            olla vierailusi kohteena olevan verkkosivuston (ensimmäisen
            osapuolen) tai kolmannen osapuolen palveluiden asettamia. Ne eivät
            kerää henkilökohtaisia tietoja sinusta.
            <br />
            <br />
            Käytämme sivustollamme suorituskykyevästeitä.
          </p>

          <p className="small-text">
            <b className="paragraph-title">
              Miten voit valvoa verkkosivustomme evästeiden käyttöä?
            </b>
            <br />
            Sinulla on oikeus päättää, hyväksytkö vai hylkäätkö verkkosivustomme
            evästeet. Voit hallita evästeasetuksiasi Cookie Consent Managerissa.
            Cookie Consent Managerin avulla voit valita, mitkä evästeluokat
            hyväksyt tai hylkäät. Välttämättömiä evästeitä ei voi hylätä, koska
            ne ovat ehdottoman välttämättömiä, jotta voimme tarjota sinulle
            palveluja verkkosivustollamme.
            <br />
            <br />
            Voit myös asettaa tai muuttaa evästeasetuksiasi selaimesi asetuksia
            hallinnoimalla. Koska jokainen verkkoselain on erilainen, tutustu
            verkkoselaimesi antamiin ohjeisiin (yleensä ”ohje”-osiossa). Jos
            päätät kieltäytyä evästeiden käytöstä tai poistaa ne käytöstä, voit
            silti käyttää verkkosivustoa, mutta osa verkkosivuston toiminnoista
            ei ehkä ole käytettävissäsi.
          </p>

          <p className="small-text">
            <b className="paragraph-title">
              Kuinka usein päivitämme tätä evästekäytäntöä?
            </b>
            <br />
            Saatamme päivittää tätä evästekäytäntöä aika ajoin, jotta voimme
            ottaa huomioon käyttämiimme evästeisiin ja niihin liittyviin
            tekniikoihin tehdyt muutokset tai muista toiminnallisista,
            oikeudellisista tai sääntelyyn liittyvistä syistä.
            <br />
            <br />
            Aina kun käytät verkkosivustoamme, sovelletaan evästekäytännön
            nykyistä versiota. Kun käytät verkkosivustoamme, tarkista tämän
            evästekäytännön päivämäärä (joka näkyy tämän asiakirjan yläosassa)
            ja tarkista mahdolliset muutokset viimeisimmän version jälkeen.
          </p>

          <p className="small-text">
            <b className="paragraph-title">Mistä saat lisätietoja?</b>
            <br />
            Jos sinulla on evästekäytäntöämme koskevia kysymyksiä tai
            huolenaiheita, voit ottaa meihin yhteyttä käyttämällä seuraavia
            tietoja:
            <br />
            <br />
            support@jaakko.com
          </p>
        </Col>
      </div>
    </Container>
  );
};

export default CookiePolicy;
