import React, { useState } from "react";
import "./SignupLoginModal.scss";
import { getCookie } from "../AppContent";

type Props = {
  toggleSignupLoginModalVisible: () => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
};

const Logout = (props: Props) => {
  const { toggleSignupLoginModalVisible, setIsLoggedIn } = props;
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleSubmitLogout = async () => {
    setIsLoggingOut(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": getCookie("csrf_access_token")
        }
      });

      if (response.ok) {
        setIsLoggedIn(false);
        toggleSignupLoginModalVisible();
        setIsLoggingOut(false);
      } else {
        setIsLoggingOut(false);
        console.error("Logout failed");
      }
    } catch (error) {
      setIsLoggingOut(false);
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="signup-login-modal__content">
        <div className="signup-login-modal__content__title">Kirjaudu ulos</div>
      </div>
      <form
        className="signup-login-modal__content__form"
        onSubmit={e => {
          e.preventDefault();
          handleSubmitLogout();
        }}
      >
        <button
          className="signup-login-modal__content__button"
          disabled={isLoggingOut}
          type="submit"
        >
          {isLoggingOut ? (
            <span className="loading-dots">
              <span></span>
            </span>
          ) : (
            "Jatka"
          )}
        </button>
      </form>
    </>
  );
};

export default Logout;
