import React, { useState, useEffect, useCallback, useRef } from "react";
import { Row } from "react-bootstrap";
import "../App.scss";
import "./AppContent.scss";
import SearchInput from "./search/SearchInput";
import MainMenu from "./menu/MainMenu";
import SignupLogin from "./SignupLogin/SignupLogin";
import Report from "./Report/Report";
import {
  BuildingWithConvertedAddresses,
  Comment,
  LatLng
} from "./HouseMap/types";
import SignupLoginModal from "./SignupLoginModal/SignupLoginModal";
import BuildingMap from "./HouseMap/BuildingMap";
import "./AppContent.scss";
import PageMetaTags from "./Report/PageMetaTags";
import FeedIcon from "./FeedIcon";
import MapIcon from "./MapIcon";
import { getTimeAgo } from "./Report/Report.helpers";
import Linkify from "linkify-react";
import DOMPurify from "dompurify";
import "./FeedComments.scss";
import LeftArrow from "./LeftArrow";
import LoadingSpinner from "./common/LoadingSpinner";
import RefreshIcon from "./RefreshIcon";
import LocationIcon from "./LocationIcon";
import TagManager from "react-gtm-module";
// U1tility function to get a cookie by name
export function getCookie(name: string) {
  const cookieStr = document.cookie;
  const cookies = cookieStr.split(";");
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
}

const AppContent = () => {
  const [reportVisible, setReportVisible] = useState(false);
  const [
    selectedBuilding,
    setSelectedBuilding
  ] = useState<BuildingWithConvertedAddresses | null>(null);
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const refreshTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isRefreshingRef = useRef(false);
  const [expiresAt, setExpiresAt] = useState<number | null>(null);
  const [triggeredFromSearch, setTriggeredFromSearch] = useState(false);
  const [mapCenter, setMapCenter] = useState<LatLng>({
    lat: 60.168495304773955,
    lng: 24.92855807961784
  });
  const [feedViewActive, setFeedViewActive] = useState(false);
  const [feedComments, setFeedComments] = useState<Comment[]>([]);
  const [showFeed, setShowFeed] = useState(true);
  const [isRefreshingFeed, setIsRefreshingFeed] = useState(false);
  const [showImageModal, setShowImageModal] = useState<string | null>(null);

  const handleToggleView = useCallback(() => {
    // TODO: A more elegant solution needed, now feed is polled on mobile before it is shown.
    // Show feed is by default true, but it is only used on desktop.
    // No need to keep polling on mobile if feed is not shown.
    setShowFeed(false);
    setFeedViewActive(prev => !prev);
  }, []);

  // Ensure refreshToken is stable and doesn't change on every render
  const refreshToken = useCallback(async () => {
    if (isRefreshingRef.current) return;
    isRefreshingRef.current = true;
    try {
      const csrfToken = getCookie("csrf_refresh_token");
      if (!csrfToken) {
        console.error("CSRF token not found");
        setIsLoggedIn(false);
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/refresh-token`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": csrfToken
          }
        }
      );
      if (response.ok) {
        const data = await response.json();
        setIsLoggedIn(true);
        const newExpiresAt = Date.now() + data.expires_in * 1000;
        setExpiresAt(newExpiresAt); // Update expiresAt to schedule next refresh
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      setIsLoggedIn(false);
    } finally {
      isRefreshingRef.current = false;
    }
  }, []);

  // Effect to schedule token refresh when expiresAt changes
  useEffect(() => {
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
    }

    if (expiresAt != null) {
      const minRefreshInterval = 60 * 1000; // 60 seconds in milliseconds
      const timeUntilExpiry = expiresAt - Date.now();

      if (timeUntilExpiry > minRefreshInterval) {
        const refreshTime = timeUntilExpiry - minRefreshInterval;
        refreshTimeoutRef.current = setTimeout(() => {
          refreshToken();
        }, refreshTime);
      } else if (timeUntilExpiry > 0) {
        console.warn("Token expires soon; refreshing token immediately.");
        refreshToken();
      } else {
        console.warn("Token has expired; user will be logged out.");
        setIsLoggedIn(false);
      }
    }

    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  }, [expiresAt, refreshToken]); // Only depends on expiresAt

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/check-login-status`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "X-CSRF-TOKEN": getCookie("csrf_access_token")
            }
          }
        );
        const data = await response.json();
        setIsLoggedIn(data.isLoggedIn);
        if (data.isLoggedIn && data.expires_in) {
          const newExpiresAt = Date.now() + data.expires_in * 1000;
          setExpiresAt(newExpiresAt); // Update expiresAt to schedule next refresh
        }
      } catch (error) {
        console.error("Error checking login status:", error);
        setIsLoggedIn(false);
      }
    };

    checkLoginStatus();

    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  }, []);

  const toggleReportVisible = useCallback(() => {
    setReportVisible(prev => !prev);
  }, []);

  const selectBuilding = useCallback(
    building => {
      if (building.centroid) {
        setMapCenter({
          lat: building.centroid.lat,
          lng: building.centroid.lng
        });
      }
      setSelectedBuilding(building);
      toggleReportVisible();
    },
    [setMapCenter, setSelectedBuilding, toggleReportVisible]
  );

  // Modify handleLoginSuccess to accept expires_in and set expiresAt
  const handleLoginSuccess = useCallback((expiresIn: number) => {
    setIsLoggedIn(true);
    const newExpiresAt = Date.now() + expiresIn * 1000;
    setExpiresAt(newExpiresAt); // Update expiresAt to schedule next refresh
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const userCoordinates: LatLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setMapCenter(userCoordinates);
      },
      error => {
        console.error("Error getting user location:", error);
      }
    );

    // Check for 'building-id' in the URL on component mount
    const params = new URLSearchParams(window.location.search);
    const buildingId = params.get("building-id");
    if (buildingId) {
      // Fetch the building data using the buildingId
      fetch(
        `${process.env.REACT_APP_API_URL}/report?building-id=${buildingId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": getCookie("csrf_access_token")
          }
        }
      )
        .then(response => response.json())
        .then(data => {
          if (data && data.data) {
            setSelectedBuilding(data.data);
            setReportVisible(true);
          } else {
            console.error("No data found for the given building ID");
          }
        })
        .catch(error => {
          console.error("Error fetching building by ID:", error);
        });
    }
  }, []);

  // Update the URL when the report visibility or selected building changes
  useEffect(() => {
    const url = new URL(window.location.href);
    if (selectedBuilding && reportVisible) {
      // Add 'building-id' to the URL
      url.searchParams.set("building-id", selectedBuilding.id);
      window.history.pushState({}, "", url.toString());
    } else {
      // Remove 'building-id' from the URL
      url.searchParams.delete("building-id");
      window.history.pushState({}, "", url.toString());
    }
  }, [selectedBuilding, reportVisible]);

  const fetchFeedComments = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/feed-comments`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "X-CSRF-TOKEN": getCookie("csrf_access_token")
          }
        }
      );
      const data = await response.json();
      setFeedComments(data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  }, []);

  const handleRefreshFeedClick = useCallback(async () => {
    setIsRefreshingFeed(true);
    await fetchFeedComments();
    setIsRefreshingFeed(false);
  }, [fetchFeedComments]);

  useEffect(() => {
    if (feedViewActive || showFeed) {
      fetchFeedComments();
    }
  }, [feedViewActive, showFeed, fetchFeedComments]);

  useEffect(() => {
    if (feedViewActive || showFeed) {
      const intervalId = setInterval(() => {
        fetchFeedComments();
      }, 30000); // 30 seconds in milliseconds

      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [feedViewActive, showFeed, fetchFeedComments]);

  const handleFeedCommentClick = useCallback(
    async (comment: Comment) => {
      try {
        // If you need to fetch building data from an API:
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/report?building-id=${comment.buildingId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-TOKEN": getCookie("csrf_access_token")
            }
          }
        );
        const data = await response.json();
        // "data.data" (or however you structure your response) is your building
        const building = data.data;

        // 1) Center the map to the building's centroid
        if (building.centroid) {
          setMapCenter({
            lat: building.centroid.lat,
            lng: building.centroid.lng
          });
          setTriggeredFromSearch(true);
        }

        // 2) "Select" the building to open the report
        selectBuilding(building);
      } catch (error) {
        console.error("Error fetching building from comment:", error);
      }
    },
    [selectBuilding, setMapCenter, setTriggeredFromSearch]
  );

  const handleShowSignupLoginModal = useCallback(() => {
    setLoginModalVisible(true);
    TagManager.dataLayer({
      dataLayer: {
        event: "open_signup_login_modal",
        category: "User Interaction",
        action: "Click",
        label: "Open Signup Login Modal"
      }
    });
  }, [setLoginModalVisible]);

  return (
    <div>
      <PageMetaTags
        title="Jaakko.com | Asumisen hiljainen tieto. Kysy ja keskustele."
        description="Unlocking property exploration, insights, and user interaction worldwide - Jaakko.com beta is now live!"
        ogTitle="Jaakko.com | Asumisen hiljainen tieto. Kysy ja keskustele."
        ogDescription="Unlocking property exploration, insights, and user interaction worldwide - Jaakko.com beta is now live!"
        ogImage="https://www.jaakko.com/jaakko_logo.png"
        ogUrl="https://www.jaakko.com"
      />
      <div className="p-0">
        <div
          className={`custom-navbar ${
            reportVisible ? "custom-navbar--building-view-open" : ""
          }
          ${showImageModal ? "custom-navbar--image-modal-open" : ""}
          `}
        >
          <div className="custom-navbar__section">
            <MainMenu />
          </div>
          <div className="custom-navbar__section custom-navbar__section--logo">
            <a href="/">
              <img
                className="custom-navbar__logo"
                src="/assets/logo_only.png"
                alt="Jaakko logo"
                height="41"
              />
            </a>
          </div>
          <div className="custom-navbar__section custom-navbar__section--controls">
            <SignupLogin
              toggleLoginModal={handleShowSignupLoginModal}
              isLoggedIn={isLoggedIn}
            />
          </div>
        </div>
        {loginModalVisible && (
          <SignupLoginModal
            toggleSignupLoginModalVisible={() => setLoginModalVisible(false)}
            setIsLoggedIn={setIsLoggedIn}
            isLoggedIn={isLoggedIn}
            onLoginSuccess={handleLoginSuccess}
          />
        )}
        <div
          key={feedViewActive ? "feed-active" : "feed-inactive"}
          className="app-content-wrapper"
        >
          <Row className="p-0 m-0 feed-and-map-wrapper">
            {feedViewActive ? (
              <div style={{ width: "100%", height: "calc(100vh - 66px)" }}>
                <FeedComments
                  feedComments={feedComments}
                  handleFeedCommentClick={handleFeedCommentClick}
                  handleRefreshFeedClick={handleRefreshFeedClick}
                  isRefreshingFeed={isRefreshingFeed}
                />
              </div>
            ) : (
              <>
                <div
                  className={`feed-comments ${
                    showFeed ? "feed-comments--show" : ""
                  }`}
                >
                  <div className="feed-comments__comments-list__controls">
                    <div className="feed-comments__comments-list__controls__title">
                      Fiidi
                    </div>
                    <button
                      onClick={handleRefreshFeedClick}
                      className="feed-comments__comments-list__controls__refresh-button"
                    >
                      {isRefreshingFeed ? <LoadingSpinner /> : <RefreshIcon />}
                    </button>
                  </div>
                  <div className="feed-comments__comments-list">
                    {feedComments.map((comment, index) => {
                      const commentColorIndex = (index % 9) + 1;
                      const commentAddress =
                        comment.address.street +
                        " " +
                        comment.address.houseNumber +
                        ", " +
                        comment.address.city;
                      const jaakkoCommentAddress =
                        comment.address.street +
                        " " +
                        comment.address.houseNumber;

                      return (
                        <div
                          className={`feed-comments__comment feed-comments__comment--${commentColorIndex}${
                            comment.isAdminComment
                              ? " feed-comments__comment--jaakko-comment"
                              : ""
                          }`}
                          key={comment._id}
                          onClick={() => handleFeedCommentClick(comment)}
                        >
                          <div
                            className={`feed-comments__comment__commenter-order-number-and-timestamp-wrapper${
                              !comment.content
                                ? " feed-comments__comment__commenter-order-number-and-timestamp-wrapper--no-text"
                                : ""
                            }`}
                          >
                            <div className="feed-comments__comment__commenter-order-number">
                              {comment.isAdminComment ? (
                                <div className="feed-comments__comment__commenter-order-number__jaakko-comment">
                                  <img
                                    src="/naama5.png?v=1"
                                    alt="Jaakko"
                                    height="35px"
                                    className="feed-comments__comment__commenter-order-number__jaakko-comment__jaakko-head"
                                  />
                                  <span>{jaakkoCommentAddress}</span>
                                </div>
                              ) : (
                                <>
                                  <LocationIcon />
                                  {commentAddress}
                                </>
                              )}
                            </div>
                            <div className="feed-comments__comment__timestamp">
                              {getTimeAgo(comment.timestamp)}
                            </div>
                          </div>
                          <div
                            className={`feed-comments__comment__content${
                              comment.images && comment.images.length > 0
                                ? " feed-comments__comment__content--with-image"
                                : ""
                            }`}
                          >
                            <div
                              className={`feed-comments__comment__content__text
                                ${
                                  comment.images && comment.images.length > 0
                                    ? " feed-comments__comment__content__text--with-image"
                                    : ""
                                }
                                ${
                                  !comment.content
                                    ? " feed-comments__comment__content__text--no-text"
                                    : ""
                                }`}
                            >
                              <Linkify
                                options={{
                                  attributes: {
                                    rel: "noopener noreferrer",
                                    target: "_blank"
                                  }
                                }}
                              >
                                {DOMPurify.sanitize(comment.content)}
                              </Linkify>
                            </div>
                            {comment.images && comment.images.length > 0 && (
                              <div className="feed-comments__comment__content__image-wrapper">
                                <img
                                  src={comment.images[0].thumbnailUrl}
                                  alt="Comment"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {isRefreshingFeed && (
                      <div className="feed-comments__loading-spinner-overlay">
                        <LoadingSpinner />
                      </div>
                    )}
                  </div>
                  <div className="feed-comments__close-button-wrapper">
                    <button
                      className="feed-comments__close-button-wrapper__close-button"
                      onClick={() => setShowFeed(false)}
                    >
                      <div className="feed-comments__close-button-wrapper__close-button__text">
                        S<br />u<br />l<br />j<br />e
                      </div>
                      <div className="feed-comments__close-button-wrapper__close-button__icon">
                        <LeftArrow />
                      </div>
                    </button>
                  </div>
                </div>
                <BuildingMap
                  feedIsOpen={showFeed}
                  mobileFeedIsOpen={feedViewActive}
                  mapCenter={mapCenter}
                  selectedBuilding={selectedBuilding}
                  triggeredFromSearch={triggeredFromSearch}
                  setShowFeed={setShowFeed}
                  selectBuilding={selectBuilding}
                  setTriggeredFromSearch={setTriggeredFromSearch}
                />
              </>
            )}
          </Row>
        </div>
        {!feedViewActive && (
          <SearchInput
            setMapCenter={setMapCenter}
            setTriggeredFromSearch={setTriggeredFromSearch}
          />
        )}
        {selectedBuilding && reportVisible && (
          <Report
            isLoggedIn={isLoggedIn}
            selectedBuilding={selectedBuilding}
            showImageModal={showImageModal}
            toggleReportVisible={toggleReportVisible}
            setSelectedBuilding={setSelectedBuilding}
            setLoginModalVisible={setLoginModalVisible}
            refreshFeed={handleRefreshFeedClick}
            setShowImageModal={setShowImageModal}
          />
        )}
        <button className="map-feed-toggle-button" onClick={handleToggleView}>
          {feedViewActive ? (
            <>
              <div className="map-feed-toggle-button__map-text">
                Näytä kartta
              </div>
              <MapIcon />
            </>
          ) : (
            <>
              <div className="map-feed-toggle-button__feed-text">
                Näytä fiidi
              </div>
              <FeedIcon />
            </>
          )}
        </button>
      </div>
    </div>
  );
};

// Inside your relevant component or a custom feed rendering component:
function FeedComments({
  feedComments,
  handleFeedCommentClick,
  handleRefreshFeedClick,
  isRefreshingFeed
}) {
  const [startY, setStartY] = React.useState<number | null>(null);
  const [currentY, setCurrentY] = React.useState<number | null>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);

  /**
   * This is our threshold. The user needs to pull down at least this many pixels
   * for it to trigger a refresh.
   */
  const PULL_DOWN_THRESHOLD = 60;

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    // Only start tracking if we're scrolled to the top
    if (scrollContainerRef.current?.scrollTop === 0) {
      setStartY(e.touches[0].clientY);
    }
  };

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (startY !== null) {
      setCurrentY(e.touches[0].clientY);
    }
  };

  const onTouchEnd = () => {
    if (startY !== null && currentY !== null) {
      const pulledDownDistance = currentY - startY;
      // If we pulled down enough, trigger handleRefreshFeedClick
      if (pulledDownDistance >= PULL_DOWN_THRESHOLD) {
        handleRefreshFeedClick();
      }
    }
    // Reset
    setStartY(null);
    setCurrentY(null);
  };

  return (
    <div
      className="feed-comments__comments-list"
      ref={scrollContainerRef}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      {feedComments.map((comment, index) => {
        const commentColorIndex = (index % 9) + 1;
        const commentAddress =
          comment.address.street +
          " " +
          comment.address.houseNumber +
          ", " +
          comment.address.city;
        const jaakkoCommentAddress =
          comment.address.street + " " + comment.address.houseNumber;

        return (
          <div
            className={`feed-comments__comment feed-comments__comment--${commentColorIndex}${
              comment.isAdminComment
                ? " feed-comments__comment--jaakko-comment"
                : ""
            }`}
            key={comment._id}
            onClick={() => handleFeedCommentClick(comment)}
          >
            <div
              className={`feed-comments__comment__commenter-order-number-and-timestamp-wrapper${
                !comment.content
                  ? " feed-comments__comment__commenter-order-number-and-timestamp-wrapper--no-text"
                  : ""
              }`}
            >
              {" "}
              <div className="feed-comments__comment__commenter-order-number">
                {comment.isAdminComment ? (
                  <div className="feed-comments__comment__commenter-order-number__jaakko-comment">
                    <img
                      src="/naama5.png?v=1"
                      alt="Jaakko"
                      height="35px"
                      className="feed-comments__comment__commenter-order-number__jaakko-comment__jaakko-head"
                    />
                    <span>{jaakkoCommentAddress}</span>
                  </div>
                ) : (
                  <>
                    <LocationIcon />
                    {commentAddress}
                  </>
                )}
              </div>
              <div className="feed-comments__comment__timestamp">
                {getTimeAgo(comment.timestamp)}
              </div>
            </div>
            <div
              className={`feed-comments__comment__content${
                comment.images && comment.images.length > 0
                  ? " feed-comments__comment__content--with-image"
                  : ""
              }`}
            >
              <div
                className={`feed-comments__comment__content__text
                                ${
                                  comment.images && comment.images.length > 0
                                    ? " feed-comments__comment__content__text--with-image"
                                    : ""
                                }
                                ${
                                  !comment.content
                                    ? " feed-comments__comment__content__text--no-text"
                                    : ""
                                }`}
              >
                <Linkify
                  options={{
                    attributes: {
                      rel: "noopener noreferrer",
                      target: "_blank"
                    }
                  }}
                >
                  {DOMPurify.sanitize(comment.content)}
                </Linkify>
              </div>
              {comment.images && comment.images.length > 0 && (
                <div className="feed-comments__comment__content__image-wrapper">
                  <img src={comment.images[0].thumbnailUrl} alt="Comment" />
                </div>
              )}
            </div>
          </div>
        );
      })}
      {isRefreshingFeed && (
        <div className="feed-comments__loading-spinner-overlay">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
}

export default AppContent;
