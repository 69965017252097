import React from "react";
import { Helmet } from "react-helmet"; // Import Helmet

const PageMetaTags = ({
  title,
  description,
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl
}: {
  title: string;
  description: string;
  ogTitle: string;
  ogDescription: string;
  ogImage: string;
  ogUrl: string;
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default PageMetaTags;
