import React, { useState, useRef, useEffect } from "react";
import "./SignupLoginModal.scss";
import { getCookie } from "../AppContent";
import LoginOrSignup from "./LoginOrSignup";
import Logout from "./Logout";
import Login from "./Login";
import Signup from "./Signup";
import { CloseIcon } from "../common/CloseIcon";

type Props = {
  toggleSignupLoginModalVisible: () => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  isLoggedIn: boolean;
  onLoginSuccess: (expiresIn: number) => void; // Add this new prop
};

const SignupLoginModal = (props: Props) => {
  const {
    toggleSignupLoginModalVisible,
    setIsLoggedIn,
    isLoggedIn,
    onLoginSuccess
  } = props;
  const [email, setEmail] = useState("");
  const [showLoginView, setShowLoginView] = useState(false);
  const [showSignupView, setShowSignupView] = useState(false);
  const [
    showEmailVerificationCodeInputView,
    setShowEmailVerificationCodeInputView
  ] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [loginError, setLoginError] = useState(""); // Add this line
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
  const [verificationError, setVerificationError] = useState(""); // Add this line

  const verificationCodeRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showEmailVerificationCodeInputView && verificationCodeRef.current) {
      verificationCodeRef.current.focus();
    }
  }, [showEmailVerificationCodeInputView]);

  const validatePassword = (password: string) => {
    if (password.length < 8) {
      return "Salasanan täytyy olla vähintään 8 merkkiä pitkä.";
    }
    if (!/[A-Z]/.test(password)) {
      return "Salasanan täytyy sisältää vähintään yksi iso kirjain.";
    }
    if (!/[a-z]/.test(password)) {
      return "Salasanan täytyy sisältää vähintään yksi pieni kirjain.";
    }
    if (!/[0-9]/.test(password)) {
      return "Salasanan täytyy sisältää vähintään yksi numero.";
    }
    return "";
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) ? "" : "Virheellinen sähköpostiosoite.";
  };

  const handleSubmitEmailVerificationCode = async () => {
    setIsVerifyingEmail(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/confirm-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": getCookie("csrf_access_token")
          },
          body: JSON.stringify({ email, verification_code: verificationCode }),
          credentials: "include"
        }
      );

      if (response.ok) {
        const data = await response.json();
        setIsLoggedIn(true);
        toggleSignupLoginModalVisible();
        onLoginSuccess(data.expires_in);
      } else {
        const errorData = await response.json();
        if (errorData.error === "invalid_code") {
          setVerificationError("Invalid verification code. Please try again."); // Set error message
        } else {
          console.error("Email confirmation code failed");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsVerifyingEmail(false);
    }
  };

  return (
    <div className="signup-login-modal-overlay">
      <div
        className={`signup-login-modal ${
          showSignupView ? "signup-login-modal--signup" : ""
        } ${
          showEmailVerificationCodeInputView
            ? "signup-login-modal--email-verification"
            : ""
        } ${isLoggedIn ? "signup-login-modal--logout-view" : ""} ${
          !showLoginView &&
          !showSignupView &&
          !showEmailVerificationCodeInputView &&
          !isLoggedIn
            ? "signup-login-modal--login"
            : ""
        }`}
        onClick={e => e.stopPropagation()}
      >
        <div className="signup-login-modal__close-wrapper">
          <button
            onClick={toggleSignupLoginModalVisible}
            className="signup-login-modal__close-wrapper__close-button"
          >
            <CloseIcon size={20} />
          </button>
        </div>
        {!showLoginView &&
          !showSignupView &&
          !showEmailVerificationCodeInputView &&
          !isLoggedIn && (
            <LoginOrSignup
              email={email}
              toggleSignupLoginModalVisible={toggleSignupLoginModalVisible}
              setShowLoginView={setShowLoginView}
              setShowSignupView={setShowSignupView}
              validateEmail={validateEmail}
              onLoginSuccess={onLoginSuccess}
              setIsLoggedIn={setIsLoggedIn}
              setEmail={setEmail}
            />
          )}
        {isLoggedIn && (
          <Logout
            toggleSignupLoginModalVisible={toggleSignupLoginModalVisible}
            setIsLoggedIn={setIsLoggedIn}
          />
        )}
        {showLoginView && (
          <Login
            email={email}
            loginError={loginError}
            toggleSignupLoginModalVisible={toggleSignupLoginModalVisible}
            onLoginSuccess={onLoginSuccess}
            setLoginError={setLoginError}
            validatePassword={validatePassword}
          />
        )}
        {showSignupView && (
          <Signup
            email={email}
            setEmail={setEmail}
            setShowSignupView={setShowSignupView}
            setShowLoginView={setShowLoginView}
            validatePassword={validatePassword}
            setShowEmailVerificationCodeInputView={
              setShowEmailVerificationCodeInputView
            }
            setLoginError={setLoginError}
            validateEmail={validateEmail}
          />
        )}
        {showEmailVerificationCodeInputView && (
          <>
            <div className="signup-login-modal__content">
              <div className="signup-login-modal__content__title">
                Vahvista sähköpostiosoite
              </div>
            </div>
            <div className="signup-login-modal__content__subtitle">
              <div>Anna vahvistuskoodi.</div>
              <div>
                Anna koodi, joka lähetettiin sähköpostiosoitteeseesi {email}{" "}
                (jos et näe sähköpostia, tarkista roskapostikansio)
              </div>
            </div>
            <form
              className="signup-login-modal__content__form"
              onSubmit={e => {
                e.preventDefault();
                handleSubmitEmailVerificationCode();
              }}
            >
              <input
                type="text"
                placeholder="Vahvistuskoodi"
                className="signup-login-modal__content__input"
                value={verificationCode}
                onChange={e => setVerificationCode(e.target.value)}
                required
                ref={verificationCodeRef}
              />
              {verificationError && ( // Add this block
                <div className="signup-login-modal__content__error">
                  {verificationError}
                </div>
              )}
              <button
                className="signup-login-modal__content__button"
                disabled={isVerifyingEmail}
                type="submit"
              >
                {isVerifyingEmail ? (
                  <span className="loading-dots">
                    <span></span>
                  </span>
                ) : (
                  "Seuraava"
                )}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default SignupLoginModal;
