import React from "react";
import "./components.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import MainMenu from "../../menu/MainMenu";
import "./PolicyPage.scss";

const PrivacyPolicy = () => {
  const history = useHistory();
  return (
    <Container className="p-0 policy-page-container">
      <MainMenu isMainMenuPage />
      <div className="closeMenuView" onClick={() => history.push("/")} />
      <div className="policy-page-content">
        <Link to="/">
          <div className="flatsonBlueText">Jaakko.com</div>
        </Link>
        <Row>
          <p className="page-name">Jaakkocom oy tietosuoja</p>
        </Row>
        <Col>
          <p className="small-text">
            Yksityisyytesi on meille tärkeää. Jaakkocom Oy kunnioittaa
            yksityisyyttäsi ja noudattaa kaikkia sovellettavia lakeja ja
            asetuksia koskien kaikkia henkilötietoja, joita voimme kerätä
            sinusta, myös <a href="https://jaakko.com">jaakko.com</a>
            -verkkosivustollamme ja muilla omistamillamme ja ylläpitämillämme
            sivustoilla.
            <br />
            <br />
            Henkilötiedot ovat kaikkia sinua koskevia tietoja, joiden avulla
            sinut voidaan tunnistaa. Näitä ovat tiedot sinusta henkilönä (kuten
            nimi, osoite ja syntymäaika), laitteistasi, maksutiedot ja tiedot
            siitä, miten käytät verkkosivustoa tai verkkopalvelua.
            <br />
            <br />
            Jos sivustomme sisältää linkkejä kolmansien osapuolten sivustoihin
            ja palveluihin, ota huomioon, että näillä sivustoilla ja palveluilla
            on omat tietosuojakäytäntönsä. Kun seuraat linkkiä kolmannen
            osapuolen sisältöön, sinun on syytä lukea niiden julkaisemat
            tietosuojakäytäntöä koskevat tiedot siitä, miten ne keräävät ja
            käyttävät henkilökohtaisia tietoja. Tätä tietosuojakäytäntöä ei
            sovelleta mihinkään toimintaasi sen jälkeen, kun olet poistunut
            sivustoltamme.
            <br />
            <br />
            Nämä ehdot ovat voimassa 28. lokakuuta 2024 alkaen.
            <br />
            <br />
            Viimeksi päivitetty: 09 joulukuuta 2024
          </p>

          <p className="small-text">
            <b className="paragraph-title">Keräämämme tiedot</b>
            <br />
            Keräämämme tiedot kuuluvat kahteen ryhmään: "vapaaehtoisesti
            annetut" tiedot ja "automaattisesti kerätyt" tiedot.
            <br />
            <br />
            "Vapaaehtoisesti annetuilla" tiedoilla tarkoitetaan tietoja, jotka
            annat meille tietoisesti ja aktiivisesti käyttäessäsi tai
            osallistuessasi palveluidemme käyttöön.
            <br />
            <br />
            "Automaattisesti kerätyt" tiedot viittaavat kaikkiin tietoihin,
            joita laitteesi lähettävät automaattisesti käyttäessäsi tuotteitamme
            ja palveluitamme.
          </p>

          <p className="small-text">
            <b className="paragraph-title">Lokitiedot</b>
            <br />
            Kun vierailet verkkosivustollamme, palvelimemme voivat
            automaattisesti tallentaa selaimesi antamat vakiotiedot. Näihin
            tietoihin voi sisältyä laitteesi IP-osoite, selaimesi tyyppi ja
            versio, sivut, joilla vierailet, vierailun aika ja päivämäärä,
            kullakin sivulla vietetty aika sekä muita vierailua koskevia
            tietoja.
            <br />
            <br />
            Lisäksi, jos kohtaat tiettyjä virheitä käyttäessäsi sivustoa,
            saatamme automaattisesti kerätä tietoja virheestä ja sen syntyyn
            liittyvistä olosuhteista. Näihin tietoihin voi sisältyä teknisiä
            tietoja laitteestasi, siitä, mitä yritit tehdä virheen sattuessa, ja
            muita ongelmaan liittyviä teknisiä tietoja. Saatat saada ilmoituksen
            tällaisista virheistä, jopa niiden esiintymisajankohtana, siitä,
            että ne ovat tapahtuneet, tai siitä, millainen virhe on.
            <br />
            <br />
            Huomioithan, että vaikka nämä tiedot eivät välttämättä ole
            sellaisenaan henkilökohtaisesti tunnistettavia, niitä voi olla
            mahdollista yhdistää muihin tietoihin yksittäisten henkilöiden
            henkilökohtaiseksi tunnistamiseksi.
          </p>

          <p className="small-text">
            <b className="paragraph-title">Laitteen tiedot</b>
            <br />
            Kun vierailet verkkosivustollamme tai olet vuorovaikutuksessa
            palveluidemme kanssa, saatamme kerätä automaattisesti tietoja
            laitteestasi, kuten maantieteelliset sijaintitiedot.
            <br />
            <br />
            Keräämämme tiedot voivat riippua laitteesi ja ohjelmistosi
            yksilöllisistä asetuksista.
          </p>

          <p className="small-text">
            <b className="paragraph-title">Henkilökohtaiset tiedot</b>
            <br />
            Saatamme pyytää henkilökohtaisia tietoja - esimerkiksi kun
            rekisteröidyt palvelun käyttäjäksi, lähetät meille sisältöä tai kun
            otat meihin yhteyttä - jotka voivat sisältää yhden tai useamman
            seuraavista:
            <br />
            <br />
            <ul>
              <li>Nimi</li>
              <li>Sähköposti</li>
              <li>Osoite</li>
              <li>Puhelinnumero</li>
              <li>Kansalaisuus</li>
              <li>Ikä</li>
              <li>Sukupuoli</li>
            </ul>
          </p>

          <p className="small-text">
            <b className="paragraph-title">Käyttäjien tuottama sisältö</b>
            <br />
            Käyttäjien tuottamaksi sisällöksi katsomme aineiston (teksti-, kuva-
            ja/tai videosisällön), jonka käyttäjät toimittavat meille
            vapaaehtoisesti julkaistavaksi verkkosivustollamme tai uudelleen
            julkaistavaksi sosiaalisen median kanavissamme. Kaikki käyttäjän
            luoma sisältö liittyy tiliin tai sähköpostiosoitteeseen, jota
            käytettiin aineiston lähettämiseen.
            <br />
            <br />
            Huomaa, että kaikki julkaisemista varten lähettämäsi sisältö on
            julkista julkaisemisen (ja sen jälkeisen tarkistuksen tai
            tarkistusprosessin) jälkeen. Julkaisemisen jälkeen se voi olla
            sellaisten kolmansien osapuolten saatavilla, jotka eivät kuulu tämän
            tietosuojakäytännön piiriin.
          </p>

          <p className="small-text">
            <b className="paragraph-title">
              Henkilötietojesi käsittelyn lailliset syyt
            </b>
            <br />
            Keräämme ja käytämme henkilötietojasi vain silloin, kun meillä on
            siihen oikeutettu syy. Tällöin keräämme vain sellaisia
            henkilötietoja, jotka ovat kohtuudella välttämättömiä palveluidemme
            tarjoamiseksi sinulle.
          </p>

          <p className="small-text">
            <b className="paragraph-title">Tietojen kerääminen ja käyttö</b>
            <br />
            Saatamme kerätä henkilötietoja sinusta, kun teet verkkosivustollamme
            jotakin seuraavista:
            <br />
            <br />
            <ul>
              <li>Rekisteröidyt tai kirjaudut palveluun</li>
              <li>Osallistut kilpailuihin, arvontoihin tai kyselyihin</li>
              <li>
                Rekisteröidyt vastaanottamaan päivityksiä meiltä sähköpostitse
                tai sosiaalisen median kanavissa
              </li>
              <li>
                Käytät mobiililaitetta tai verkkoselainta sisällön käyttämiseen
              </li>
              <li>
                Otat meihin yhteyttä sähköpostitse, sosiaalisessa mediassa tai
                muilla vastaavilla tavoilla
              </li>
              <li>Kun mainitset meidät sosiaalisessa mediassa</li>
            </ul>
            Voimme kerätä, säilyttää, käyttää ja luovuttaa tietoja seuraaviin
            tarkoituksiin, eikä henkilötietoja käsitellä myöhemmin tavalla, joka
            on ristiriidassa näiden tarkoitusten kanssa:
            <br />
            <br />
            <ul>
              <li>
                Alustamme keskeisten ominaisuuksien ja palvelujen tarjoaminen
                sinulle
              </li>
              <li>
                Jotta voit mukauttaa tai personoida kokemustasi
                verkkosivustollamme
              </li>
              <li>Toimittaa sinulle tuotteita ja/tai palveluja</li>
              <li>Ottaa sinuun yhteyttä ja viestiä kanssasi</li>
              <li>
                Markkinatutkimus ja analytiikka sekä liiketoiminnan
                kehittäminen, mukaan lukien verkkosivuston, siihen liittyvien
                sovellusten ja siihen liittyvien sosiaalisen median alustojen
                käyttö ja parantaminen
              </li>
              <li>
                Mainontaan ja markkinointiin, mukaan lukien mainostietojen
                lähettäminen sinulle tuotteistamme ja palveluistamme sekä
                kolmansista osapuolista, joiden katsomme voivan kiinnostaa sinua
              </li>
              <li>
                Jotta voit käyttää verkkosivustoamme, siihen liittyviä
                sovelluksia ja sosiaalisen median alustoja
              </li>
              <li>Hallinnollisiin tarkoituksiin</li>
              <li>
                Kilpailujen järjestäminen, arvonnat ja/tai lisäetujen
                tarjoaminen sinulle
              </li>
              <li>
                Täyttääksemme lakisääteiset velvoitteemme ja ratkaistaksemme
                mahdolliset riidat
              </li>
              <li>
                Määrittääksemme verkkosivustollamme julkaisemasi sisällön (esim.
                viestit ja kommentit).
              </li>
              <li>
                Turvallisuuteen ja petosten estämiseen sekä sen varmistamiseen,
                että sivustomme ja sovelluksemme ovat turvallisia ja niitä
                käytetään käyttöehtojemme mukaisesti
              </li>
              <li>
                Tekniseen arviointiin, mukaan lukien verkkosivustomme ja
                sovelluksemme, niihin liittyvien sovellusten ja sosiaalisen
                median alustojen käyttäminen ja parantaminen
              </li>
            </ul>
            Voimme yhdistää vapaaehtoisesti annettuja ja automaattisesti
            kerättyjä henkilötietoja yleisiin tietoihin tai tutkimustietoihin,
            joita saamme muista luotettavista lähteistä. Esimerkiksi
            markkinointi- ja markkinatutkimustoimintamme voivat paljastaa
            tietoja ja oivalluksia, joita voimme yhdistää tietoihin siitä, miten
            kävijät käyttävät sivustoamme, parantaaksemme sivustoamme ja
            käyttökokemustasi.
          </p>

          <p className="small-text">
            <b className="paragraph-title">Henkilötietojesi turvallisuus</b>
            <br />
            Kun keräämme ja käsittelemme henkilötietoja ja kun säilytämme näitä
            tietoja, suojaamme niitä kaupallisesti hyväksyttävin keinoin
            estääksemme tietojen katoamisen ja varastamisen sekä luvattoman
            pääsyn, paljastamisen, kopioinnin, käytön tai muuttamisen.
            <br />
            <br />
            Vaikka teemme parhaamme suojellaksemme palvelussa olevia
            henkilötietoja, huomautamme, että mikään sähköinen siirto- tai
            tallennusmenetelmä ei ole 100-prosenttisen turvallinen, eikä kukaan
            voi taata ehdotonta tietoturvaa.
            <br />
            <br />
            Olet itse vastuussa salasanan valinnasta ja sen yleisestä
            turvallisuusvahvuudesta ja omien tietojesi turvallisuuden
            varmistamisesta palveluidemme puitteissa. Varmista esimerkiksi, että
            kaikki henkilökohtaisiin tietoihin ja tileihin pääsyyn liittyvät
            salasanat ovat turvallisia ja luottamuksellisia.
          </p>

          <p className="small-text">
            <b className="paragraph-title">
              Kuinka kauan säilytämme henkilökohtaisia tietojasi
            </b>
            <br />
            Säilytämme henkilötietojasi vain niin kauan kuin on tarpeen. Tämä
            ajanjakso voi riippua siitä, mihin käytämme tietojasi tämän
            tietosuojakäytännön mukaisesti. Jos olet esimerkiksi antanut meille
            henkilökohtaisia tietoja luodessasi meille tilin, saatamme säilyttää
            näitä tietoja niin kauan kuin tilisi on olemassa järjestelmässämme.
            Jos henkilötietojasi ei enää tarvita tähän tarkoitukseen, poistamme
            ne tai teemme niistä nimettömiä poistamalla kaikki sinut tunnistavat
            tiedot.
            <br />
            <br />
            Voimme kuitenkin tarvittaessa säilyttää henkilötietojasi
            oikeudellisten, kirjanpito- tai raportointivelvoitteiden
            noudattamiseksi tai yleisen edun mukaisia arkistointitarkoituksia
            varten.
          </p>

          <p className="small-text">
            <b className="paragraph-title">
              Henkilötietojen luovuttaminen kolmansille osapuolille
            </b>
            <br />
            Voimme luovuttaa henkilötietoja seuraaville tahoille:
            <br />
            <br />
            <ul>
              <li>Yhtiömme emoyhtiö, tytäryhtiö tai osakkuusyhtiö</li>
              <li>
                Kolmannen osapuolen palveluntarjoajat, jotta ne voivat tarjota
                palvelujaan, mukaan lukien (rajoituksetta)
                tietotekniikkapalvelujen tarjoajat, tietojen tallennuksen,
                isännöinnin ja palvelinten tarjoajat, mainosverkostot,
                analytiikka, velanperintätoimistot, ylläpito- tai
                ongelmanratkaisutoimistot, markkinointi- tai mainostoimistot,
                ammatilliset neuvonantajat ja maksujärjestelmien ylläpitäjät
              </li>
              <li>
                Työntekijämme, alihankkijamme ja/tai niihin liittyvät yhteisöt
              </li>
              <li>Nykyiset tai mahdolliset edustajamme tai liikekumppanimme</li>
              <li>
                Järjestämiemme kilpailujen, arvontojen tai kampanjoiden
                sponsorit tai promoottorit
              </li>
              <li>Luottotietoyhtiöt, tuomioistuimet ja sääntelyviranomaiset</li>
              <li>
                Tuomioistuimille, sääntelyviranomaisille ja
                lainvalvontaviranomaisille lain edellyttämällä tavalla
                todellisten tai tulevien oikeudenkäyntien yhteydessä tai
                laillisten oikeuksiemme vahvistamiseksi, käyttämiseksi tai
                puolustamiseksi
              </li>
              <li>
                Kolmansille osapuolille, mukaan lukien asiamiehet tai
                alihankkijat, jotka avustavat meitä tietojen, tuotteiden,
                palvelujen tai suoramarkkinoinnin tarjoamisessa sinulle
              </li>
              <li>
                Kolmansille osapuolille tietojen keräämistä ja käsittelyä varten
              </li>
              <li>
                Taho, joka ostaa tai jolle siirrämme kaikki tai olennaisilta
                osin kaikki omaisuutemme ja liiketoimintamme
              </li>
            </ul>
            Tällä hetkellä käyttämiämme kolmansia osapuolia ovat:
            <br />
            <br />
            <ul>
              <li>Google Analytics</li>
              <li>Google Tag Manager</li>
            </ul>
          </p>

          <p className="small-text">
            <b className="paragraph-title">Henkilötietojesi hallinta</b>
            <br />
            <b>Valintasi:</b> Antamalla meille henkilötietoja ymmärrät, että
            keräämme, säilytämme, käytämme ja luovutamme henkilötietojasi tämän
            tietosuojakäytännön mukaisesti. Sinun ei ole pakko antaa meille
            henkilötietoja, mutta jos et tee niin, se saattaa vaikuttaa
            verkkosivustomme tai sen kautta tarjottavien tuotteiden ja/tai
            palveluiden käyttöön.
            <br />
            <br />
            <b>Kolmansilta osapuolilta saadut tiedot:</b> Jos saamme
            henkilötietojasi kolmannelta osapuolelta, suojaamme niitä tämän
            tietosuojakäytännön mukaisesti. Jos olet kolmas osapuoli, joka antaa
            henkilötietoja jostakin toisesta henkilöstä, vakuutat ja takaat,
            että sinulla on kyseisen henkilön suostumus henkilötietojen
            antamiseen meille.
            <br />
            <br />
            <b>Markkinointilupa:</b> Jos olet aiemmin antanut suostumuksesi
            siihen, että käytämme henkilötietojasi
            suoramarkkinointitarkoituksiin, voit muuttaa mieltäsi milloin
            tahansa ottamalla meihin yhteyttä alla olevilla tiedoilla.
            <br />
            <br />
            <b>Tietoihin pääsy:</b> Voit pyytää yksityiskohtaisia tietoja
            sinusta hallussamme olevista henkilötiedoista.
            <br />
            <br />
            <b>Korjaus:</b> Jos uskot, että hallussamme olevat tietosi ovat
            virheellisiä, vanhentuneita, epätäydellisiä, epäolennaisia tai
            harhaanjohtavia, ota meihin yhteyttä tässä tietosuojakäytännössä
            annettujen tietojen avulla. Ryhdymme kohtuullisiin toimiin
            korjataksemme kaikki epätarkoiksi, epätäydellisiksi,
            harhaanjohtaviksi tai vanhentuneiksi todetut tiedot.
            <br />
            <br />
            <b>Tietosuojaloukkauksista ilmoittaminen:</b> Noudatamme
            tietosuojarikkomusten osalta meihin sovellettavia lakeja.
            <br />
            <br />
            <b>Valitukset:</b> Jos uskot, että olemme rikkoneet asiaankuuluvaa
            tietosuojalakia ja haluat tehdä valituksen, ota meihin yhteyttä alla
            olevilla tiedoilla ja ilmoita meille kaikki yksityiskohdat
            väitetystä rikkomuksesta. Tutkimme valituksesi viipymättä ja
            vastaamme sinulle kirjallisesti, jossa kerromme tutkimustulokset ja
            toimenpiteet, joihin ryhdymme valituksesi käsittelemiseksi. Sinulla
            on myös oikeus ottaa yhteyttä sääntelyviranomaiseen tai
            tietosuojaviranomaiseen valituksesi johdosta.
            <br />
            <br />
            <b>Tilauksen peruuttaminen:</b> Jos haluat peruuttaa
            sähköpostitietokannastamme tilauksen tai kieltäytyä viestinnästä
            (mukaan lukien markkinointiviestintä), ota meihin yhteyttä tässä
            tietosuojakäytännössä annettujen tietojen avulla tai poistu
            viestinnästä viestinnässä annettujen poistumismahdollisuuksien
            avulla. Saatamme joutua pyytämään sinulta tiettyjä tietoja, jotta
            voimme vahvistaa henkilöllisyytesi.
          </p>

          <p className="small-text">
            <b className="paragraph-title">Evästeiden käyttö</b>
            <br />
            Käytämme evästeitä kerätäksemme tietoja sinusta ja toiminnastasi
            sivustollamme. Eväste on pieni tekstitiedosto, jonka
            verkkosivustomme tallentaa tietokoneellesi ja johon se pääsee
            käsiksi joka kerta, kun vierailet sivustollamme, jotta voimme
            ymmärtää, miten käytät sivustoamme. Tämä auttaa meitä tarjoamaan
            sinulle sisältöä määrittelemiesi mieltymysten perusteella.
            <br />
            <br />
            Katso lisätietoja{" "}
            <a href="https://www.jaakko.com/cookie-policy">
              evästekäytännöstämme
            </a>
            .
          </p>

          <p className="small-text">
            <b className="paragraph-title">
              Liiketoiminnan mahdolliset muutokset
            </b>
            <br />
            Jos jaakko.com ostetaan tai siinä epätodennäköisessä tapauksessa,
            että lakkaamme toimimasta tai menemme konkurssiin, sisällytämme
            tiedot, mukaan lukien henkilötietosi, niihin omaisuuseriin, jotka
            siirretään meidät ostaville osapuolille. Hyväksyt, että tällaisia
            siirtoja voi tapahtua ja että meidät hankkivat osapuolet voivat
            sovellettavan lain sallimissa rajoissa jatkaa henkilötietojesi
            käyttöä tämän käytännön mukaisesti, ja heidän on omaksuttava tämä
            käytäntö, koska se on perustana kaikille omistus- tai
            käyttöoikeuksille, joita meillä on näihin tietoihin.
          </p>

          <p className="small-text">
            <b className="paragraph-title">Rajoitteet</b>
            <br />
            Verkkosivustollamme voi olla linkkejä ulkopuolisille sivustoille,
            joita me emme ylläpidä. Huomaathan, että emme voi valvoa näiden
            sivustojen sisältöä ja käytäntöjä, emmekä voi ottaa vastuuta niiden
            tietosuojakäytännöistä.
          </p>

          <p className="small-text">
            <b className="paragraph-title">Tietosuojakäytänteen muutokset</b>
            <br />
            Voimme harkintamme mukaan muuttaa tietosuojakäytäntöämme vastaamaan
            liiketoimintaprosessiemme päivityksiä, nykyisiä hyväksyttäviä
            käytäntöjä tai lainsäädännöllisiä tai viranomaismuutoksia. Jos
            päätämme muuttaa tätä tietosuojakäytäntöä, julkaisemme muutokset
            täällä samalla linkillä, jonka kautta pääset tähän
            tietosuojakäytäntöön.
            <br />
            <br />
            Jos muutokset ovat merkittäviä tai jos sovellettava laki niin
            vaatii, otamme yhteyttä sinuun (valitsemiesi viestintää koskevien
            asetusten perusteella) ja kaikkiin rekisteröityneisiin käyttäjiimme
            ja ilmoitamme uudet tiedot ja linkit päivitettyyn tai muutettuun
            käytäntöön.
            <br />
            <br />
            Jos laki niin vaatii, pyydämme sinulta luvan tai annamme sinulle
            mahdollisuuden hyväksyä tai olla hyväksymättä, tapauksen mukaan,
            henkilötietojesi uusia käyttötapoja.
          </p>

          <p className="small-text">
            <b className="paragraph-title">
              Lisätiedot yleisen tietosuoja-asetuksen (GDPR) noudattamista
              varten (EU)
            </b>
          </p>

          <p className="small-text">
            <b className="paragraph-title">
              Rekisterinpitäjä / Tietojen käsittelijä
            </b>
            <br />
            Yleisessä tietosuoja-asetuksessa erotetaan toisistaan organisaatiot,
            jotka käsittelevät henkilötietoja omiin tarkoituksiinsa (ns.
            rekisterinpitäjät), ja organisaatiot, jotka käsittelevät
            henkilötietoja muiden organisaatioiden puolesta (ns. henkilötietojen
            käsittelijät). Jaakkocom Oy, joka sijaitsee osoitteessa, joka on
            annettu Yhteystiedot-osiossa, olemme rekisterinpitäjä meille
            antamiesi henkilötietojen osalta.
          </p>

          <p className="small-text">
            <b className="paragraph-title">
              Henkilötietojesi käsittelyn oikeusperusteet
            </b>
            <br />
            Keräämme ja käytämme henkilötietojasi vain silloin, kun meillä on
            siihen laillinen oikeus. Tällöin keräämme ja käytämme
            henkilötietojasi laillisesti, oikeudenmukaisesti ja avoimesti.
            <br />
            <br />
            Lailliset perusteemme riippuvat käyttämistäsi palveluista ja siitä,
            miten käytät niitä. Tämä tarkoittaa, että keräämme ja käytämme
            tietojasi vain seuraavilla perusteilla:
          </p>

          <p className="small-text">
            <b className="paragraph-title">Sinun suostumuksesi</b>
            <br />
            Kun annat meille luvan kerätä ja käyttää henkilötietojasi tiettyyn
            tarkoitukseen. Voit peruuttaa suostumuksesi milloin tahansa
            tarjoamiemme toimintojen avulla; tämä ei kuitenkaan vaikuta jo
            tapahtuneeseen tietojesi käyttöön. Voit antaa suostumuksesi antaa
            nimesi ja yhteystietosi osallistuaksesi arvontaan tai kampanjaan.
            Voit peruuttaa osallistumisesi milloin tahansa, mutta tämä ei
            vaikuta jo suoritettuun valintaan tai arvosteluun. Jos sinulla on
            kysyttävää siitä, miten voit peruuttaa suostumuksesi, voit ottaa
            yhteyttä tämän tietosuojakäytännön Yhteystiedot-osiossa annettuihin
            tietoihin.
          </p>

          <p className="small-text">
            <b className="paragraph-title">
              Sopimuksellinen tai liiketoiminnallinen syy
            </b>
            <br />
            Kun olet tehnyt sopimuksen kanssamme tai kun on kyse esimerkiksi
            valmistelevista toimista ennen kuin teemme sopimuksen tai
            yhteistyötä kanssasi. Jos esimerkiksi otat meihin yhteyttä
            tiedustellaksesi jotain, saatamme tarvita henkilötietojasi, kuten
            nimesi ja yhteystietosi, jotta voimme vastata.
          </p>

          <p className="small-text">
            <b className="paragraph-title">Oikeutetut edut</b>
            <br />
            Jos arvioimme, että se on tarpeen oikeutettujen etujemme vuoksi,
            kuten palveluidemme tarjoamiseksi, käyttämiseksi, parantamiseksi ja
            niistä tiedottamiseksi. Katsomme, että oikeutettuja etujamme ovat
            muun muassa tutkimus ja kehittäminen, yleisömme ymmärtäminen,
            markkinointi ja palveluidemme edistäminen, palveluidemme tehokkaan
            toiminnan varmistamiseksi toteutetut toimenpiteet,
            markkinointianalyysit sekä toimenpiteet laillisten oikeuksiemme ja
            etujemme suojaamiseksi.
          </p>

          <p className="small-text">
            <b className="paragraph-title">Lain noudattaminen</b>
            <br />
            Joissakin tapauksissa meillä voi olla lakisääteinen velvollisuus
            käyttää tai säilyttää henkilötietojasi. Tällaisia tapauksia voivat
            olla muun muassa tuomioistuimen määräykset, rikostutkimukset,
            viranomaispyynnöt ja viranomaisvelvoitteet. Jos sinulla on
            kysyttävää siitä, miten säilytämme henkilötietoja lain
            noudattamiseksi, voit tiedustella asiaa tämän tietosuojakäytännön
            Yhteystiedot-osiossa annettujen tietojen avulla.
          </p>

          <p className="small-text">
            <b className="paragraph-title">
              Kansainväliset siirrot Euroopan talousalueen (ETA) ulkopuolelle
            </b>
            <br />
            Varmistamme, että kaikki henkilötietojen siirrot Euroopan
            talousalueen (ETA) maista ETA:n ulkopuolisiin maihin suojataan
            asianmukaisilla suojatoimilla, esimerkiksi käyttämällä Euroopan
            komission hyväksymiä vakiomuotoisia tietosuojalausekkeita tai
            käyttämällä sitovia yrityssääntöjä tai muita oikeudellisesti
            hyväksyttyjä keinoja.
          </p>

          <p className="small-text">
            <b className="paragraph-title">
              Oikeutesi ja henkilötietojesi hallinta
            </b>
            <br />
            <b>Rajoittaminen:</b> Sinulla on oikeus pyytää, että rajoitamme
            henkilötietojesi käsittelyä, jos (i) olet huolissasi
            henkilötietojesi oikeellisuudesta; (ii) uskot, että henkilötietojasi
            on käsitelty lainvastaisesti; (iii) tarvitset meitä säilyttämään
            henkilötietoja ainoastaan oikeudellisen vaateen esittämistä varten;
            tai (iv) olemme käsittelemässä vastalausuettasi liittyen
            käsittelyyn, joka perustuu oikeutettuihin etuihin.
            <br />
            <br />
            <b>Käsittelyn vastustaminen:</b> Sinulla on oikeus vastustaa
            henkilötietojesi käsittelyä, joka perustuu oikeutettuihin etuihimme
            tai yleiseen etuun. Jos näin tehdään, meidän on esitettävä
            käsittelylle pakottava oikeutettu peruste, joka syrjäyttää etusi,
            oikeutesi ja vapautesi, jotta voimme jatkaa henkilötietojesi
            käsittelyä.
            <br />
            <br />
            <b>Tietojen siirrettävyys:</b> Sinulla voi olla oikeus pyytää kopio
            hallussamme olevista henkilötiedoistasi. Toimitamme nämä tiedot
            mahdollisuuksien mukaan CSV-muodossa tai muussa helposti luettavassa
            muodossa. Sinulla voi myös olla oikeus pyytää, että siirrämme nämä
            henkilötiedot kolmannelle osapuolelle.
            <br />
            <br />
            <b>Poistaminen:</b> Sinulla voi olla oikeus pyytää, että poistamme
            hallussamme olevat henkilötietosi milloin tahansa, ja ryhdymme
            kohtuullisiin toimenpiteisiin poistaaksemme henkilötietosi
            nykyisistä rekistereistämme. Jos pyydät meitä poistamaan
            henkilötietosi, kerromme sinulle, miten poisto vaikuttaa
            verkkosivustomme tai tuotteidemme ja palveluidemme käyttöön. Tähän
            oikeuteen voi olla poikkeuksia erityisistä oikeudellisista syistä,
            jotka tarvittaessa kerromme sinulle vastauksessasi pyyntöösi. Jos
            lopetat tai poistat tilisi, poistamme henkilötietosi 30 päivän
            kuluessa tilisi poistamisesta. Huomaa, että hakukoneet ja vastaavat
            kolmannet osapuolet voivat edelleen säilyttää kopioita
            henkilökohtaisista tiedoistasi, jotka on julkistettu ainakin kerran,
            kuten tietyt profiilitiedot ja julkiset kommentit, myös sen jälkeen,
            kun olet poistanut tiedot palveluistamme tai poistanut tilisi
            käytöstä.
          </p>

          <p className="small-text">
            <b className="paragraph-title">Ota yhteyttä</b>
            <br />
            Jos sinulla on yksityisyyttäsi ja tietosuojakäytäntöä koskevia
            kysymyksiä voit ottaa meihin yhteyttä:
            <br />
            <br />
            support@jaakko.com
          </p>
        </Col>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
