import React from "react";
import "./ImageModal.scss";

type Props = {
  imageUrl?: string;
  closeImageModal: () => void;
};

const ImageModal = (props: Props) => {
  const { imageUrl, closeImageModal } = props;

  return (
    <div className="image-modal-overlay">
      <div
        className="image-modal image-modal--login"
        onClick={e => e.stopPropagation()}
      >
        <div className="image-modal__close-wrapper">
          <button onClick={closeImageModal} className="btn">
            <img src="/assets/close.svg" alt="Close" height="20px" />
          </button>
        </div>
        <div className="image-modal__image-wrapper">
          <img
            src={imageUrl}
            alt="Comment"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
