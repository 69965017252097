import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./mainMenu.scss";
import TagManager from "react-gtm-module";

type MainMenuProps = {
  isMainMenuPage?: boolean;
};

const MainMenu = ({ isMainMenuPage }: MainMenuProps) => {
  const [wrapper, setWrapper] = useState("wrapper");
  const [mainMenuVisibility, setMainMenuVisibility] = useState(false);

  const handleOpenClick = () => {
    setMainMenuVisibility(true);
  };

  const handleCloseClick = () => {
    setMainMenuVisibility(false);
  };

  useEffect(() => {
    if (mainMenuVisibility) {
      setWrapper(wrapper + " open");
    } else {
      setWrapper("wrapper");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainMenuVisibility]);

  return (
    <>
      <div className="main-menu-wrapper">
        <Nav defaultActiveKey="/home" className={`${wrapper} flex-column`}>
          <div className="closeMenu" onClick={() => handleCloseClick()} />
          <Nav.Link
            className="menuElement"
            as={Link}
            to="/faq"
            onClick={() => {
              handleCloseClick();
              TagManager.dataLayer({
                dataLayer: {
                  event: "what_is_jaakko_faq_clicked",
                  category: "User Interaction",
                  action: "Click",
                  label: "What is Jaakko.com FAQ Clicked"
                }
              });
            }}
          >
            Mikä Jaakko.com on?
          </Nav.Link>
          <Nav.Link
            className="menuElement"
            as={Link}
            to="/contact"
            onClick={() => handleCloseClick()}
          >
            Yhteystiedot
          </Nav.Link>
          <Nav.Link
            className="menuElement"
            as={Link}
            to="/terms-conditions"
            onClick={() => handleCloseClick()}
          >
            Käyttöehdot
          </Nav.Link>
          <Nav.Link
            className="menuElement"
            as={Link}
            to="/privacy-policy"
            onClick={() => handleCloseClick()}
          >
            Tietosuoja
          </Nav.Link>
          <Nav.Link
            className="menuElement"
            as={Link}
            to="/cookie-policy"
            onClick={() => handleCloseClick()}
          >
            Evästeet
          </Nav.Link>
        </Nav>
      </div>
      <div
        className={`menuBurger ${
          mainMenuVisibility ? "menuBurger--open" : ""
        } ${isMainMenuPage ? "menuBurger--isMainMenuPage" : ""}`}
        onClick={() =>
          mainMenuVisibility ? handleCloseClick() : handleOpenClick()
        }
      >
        <div className="burger-line"></div>
        <div className="burger-line"></div>
        <div className="burger-line"></div>
      </div>
    </>
  );
};

export default MainMenu;
