import React from "react";
import "./components.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import MainMenu from "../../menu/MainMenu";
import "./PolicyPage.scss";

const TermsConditions = () => {
  const history = useHistory();
  return (
    <Container className="p-0 policy-page-container">
      <MainMenu isMainMenuPage />
      <div className="closeMenuView" onClick={() => history.push("/")} />
      <div className="policy-page-content">
        <Link to="/">
          <div className="flatsonBlueText">Jaakko.com</div>
        </Link>
        <Row>
          <p className="page-name">Jaakkocom oy käyttöehdot</p>
        </Row>
        <Row>
          <Col>
            <p className="small-text">
              Näillä käyttöehdoilla säännellään osoitteessa{" "}
              <a href="https://jaakko.com">jaakko.com</a> sijaitsevan
              verkkosivuston ja siihen liittyvien Jaakkocom Oy:n palveluiden
              käyttöä.
              <br />
              <br />
              Käyttämällä <a href="https://jaakko.com">jaakko.com</a>-sivustoa
              sitoudut noudattamaan näitä käyttöehtoja ja kaikkia sovellettavia
              lakeja ja määräyksiä. Jos et hyväksy näitä käyttöehtoja, sinua
              kielletään käyttämästä tätä verkkosivustoa tai muita Jaakkocom
              Oy:n tarjoamia palveluita.
              <br />
              <br />
              Jaakkocom Oy pidättää oikeuden tarkistaa ja muuttaa näitä
              käyttöehtoja. Mikäli käyttöehdot päivittyvät niistä informoidaan
              erikseen ja muutokset tulevat näkyviin tällä sivulla. Kaikki
              muutokset näihin käyttöehtoihin tulevat voimaan välittömästi
              julkaisupäivästä alkaen.
              <br />
              <br />
              Nämä käyttöehdot on viimeksi päivitetty 09. joukukuuta 2024.
            </p>

            <p className="small-text">
              <b className="paragraph-title">Käyttörajoitukset</b>
              <br />
              Käyttämällä tätä verkkosivustoa sitoudut omalta osaltasi sekä
              muiden edustamiesi osapuolten puolesta, että et toimi seuraavasti:
              <br />
              <br />
              <ul>
                <li>
                  muokkaa, kopio, tee tämän verkkosivuston sisällöstä uusia
                  versioita, tai muunna mitään tämän verkkosivuston sisältämiä
                  materiaaleja ja lähdekoodia
                </li>
                <li>
                  poista tekijänoikeusmerkintöjä tämän verkkosivuston
                  materiaaleista ja sisällöistä
                </li>
                <li>
                  luovuta materiaaleja toiselle henkilölle tai kopioida niitä
                  ("mirror") millekään muulle palvelimelle
                </li>
                <li>
                  käytä tietoisesti tätä verkkosivustoa tai siihen liittyviä
                  palveluita tavalla, joka väärinkäyttää tai häiritsee mitä
                  tahansa Jaakkocom Oy:n tarjoamia palveluita
                </li>
                <li>
                  käytä tätä verkkosivustoa tai siihen liittyviä palveluja
                  häiritsevän, sopimattoman, valheellisen, säädyttömän tai
                  laittoman aineiston lähettämiseen tai julkaisemiseen
                </li>
                <li>
                  käytä tätä verkkosivustoa tai siihen liittyviä palveluja
                  sovellettavien lakien tai määräysten vastaisesti
                </li>
                <li>
                  käytä tätä verkkosivustoa luvattoman mainonnan tai roskapostin
                  lähettämiseen
                </li>
                <li>
                  kerätä käyttäjätietoja ilman käyttäjän suostumusta; tai
                  käyttää tätä verkkosivustoa tai siihen liittyviä palveluja
                  tavalla, joka voi loukata kolmansien osapuolten yksityisyyttä,
                  immateriaalioikeuksia tai muita oikeuksia
                </li>
              </ul>
            </p>

            <p className="small-text">
              <b className="paragraph-title">Immateriaalioikeudet</b>
              <br />
              Tämän verkkosivuston ja palvelun sisältämän aineiston, kuten
              tekstien, kuvien, videoiden, ohjelmistojen, muotoilujen ja
              mallien, immateriaalioikeudet kuuluvat Jaakkocom Oy:lle tai sen
              lisenssinantajille. Kaikki oikeudet ovat suojattuja sovellettavan
              tekijänoikeus-, tavaramerkki- ja muun
              immateriaalioikeuslainsäädännön mukaisesti.
              <br />
              <br />
              Jaakkocom Oy myöntää käyttäjälle rajoitetun, ei-yksinoikeudellisen
              käyttöoikeuden palveluun ja sen sisältämään aineistoon näiden
              käyttöehtojen mukaisesti. Käyttäjä saa käyttää palvelua ja sen
              sisältöä vain palvelun tavanomaisiin käyttötarkoituksiin.
              Käyttäjälle ei myönnetä muita oikeuksia aineistoon, eikä
              käyttäjällä ole oikeutta siirtää käyttöoikeutta kolmansille
              osapuolille ilman Jaakkocom Oy:n etukäteen antamaa kirjallista
              suostumusta.
            </p>

            <p className="small-text">
              <b className="paragraph-title">Käyttäjän tuottama sisältö</b>
              <br />
              Palvelun käyttäjät voivat tuottaa ja julkaista sisältöä
              palvelussa, kuten ilmoituksia, tekstejä, kuvia, videoita tai muuta
              materiaalia. Julkaisemalla sisältöä palvelussa käyttäjä myöntää
              Jaakkocom Oy:lle rajoittamattoman, ei-yksinoikeudellisen, ja
              korvauksettoman oikeuden käyttää, muokata, tallentaa, kopioida,
              julkaista ja siirtää käyttäjän tuottamaa sisältöä, mukaan lukien
              oikeuden luovuttaa näitä oikeuksia yhteistyökumppaneille.
              <br />
              <br />
              Jaakkocom Oy:llä on oikeus käyttää käyttäjän tuottamaa sisältöä
              esimerkiksi seuraaviin tarkoituksiin:
              <br />
              <br />
              <li>Palvelun kehittäminen ja ylläpito</li>
              <li>Markkinointi ja viestintä</li>
              <li>Lainsäädännön tai viranomaismääräysten noudattaminen</li>
              <li>Muu palvelun toimintaan liittyvä tarkoitus</li>
              <br />
              Käyttäjä vakuuttaa, että hänen tuottamansa sisältö ei loukkaa
              kolmansien osapuolten oikeuksia, esimerkiksi tekijänoikeuksia tai
              tavaramerkkejä. Käyttäjä vastaa itse kaikista vaatimuksista, joita
              kolmannet osapuolet voivat esittää liittyen käyttäjän tuottamaan
              sisältöön.
            </p>

            <p className="small-text">
              <b className="paragraph-title">Palvelun aineiston käyttö</b>
              <br />
              Käyttäjä ei saa kopioida, muokata, jakaa, julkaista, "peilata" tai
              siirtää palvelun sisältöä tai aineistoa millekään muulle
              palvelimelle tai alustalle ilman Jaakkocom Oy:n erillistä
              kirjallista suostumusta. Palvelun sisältöä ei myöskään saa saattaa
              yleisön saataville kaupallisessa tai muussa vastaavassa
              tarkoituksessa ilman erillistä lupaa.
            </p>

            <p className="small-text">
              <b className="paragraph-title">Linkitys</b>
              <br />
              Käyttäjällä on oikeus linkittää palvelun pääsivulle omilta
              verkkosivuiltaan. Muu linkitys, kuten yksittäisiin sisältösivuihin
              kaupallisessa tarkoituksessa, vaatii Jaakkocom Oy:n etukäteen
              antaman kirjallisen suostumuksen.
            </p>

            <p className="small-text">
              <b className="paragraph-title">Links</b>
              <br />
              Jaakkocom Oy has not reviewed all of the sites linked to its
              website and is not responsible for the contents of any such linked
              site. The inclusion of any link does not imply endorsement,
              approval or control by Jaakkocom Oy of the site. Use of any such
              linked site is at your own risk and we strongly advise you make
              your own investigations with respect to the suitability of those
              sites.
            </p>

            <p className="small-text">
              <b className="paragraph-title">
                Immateriaalioikeuksien rikkominen
              </b>
              <br />
              Kaikki immateriaalioikeuksien loukkaukset, mukaan lukien
              tekijänoikeuksien, tavaramerkkien tai muun aineettoman omaisuuden
              luvaton käyttö, ovat kiellettyjä ja voivat johtaa oikeudellisiin
              seuraamuksiin.
            </p>

            <p className="small-text">
              <b className="paragraph-title">Vastuut</b>
              <br />
              Palveluntarjoaja tarjoaa Palvelun ja sen sisällön "sellaisena kuin
              se on" ilman takuita, vakuutuksia tai vastuita. Käyttäjä käyttää
              Palvelua omalla vastuullaan, eikä Palveluntarjoaja vastaa Palvelun
              sisällön oikeellisuudesta, tietoturvariskeistä tai sopivuudesta
              tiettyyn tarkoitukseen, ellei pakottava lainsäädäntö toisin
              määrää.
              <br />
              <br />
              Ellei pakottavasta lainsäädännöstä muuta johdu, Palveluntarjoaja
              ei vastaa mistään välittömistä tai välillisistä vahingoista, kuten
              keskeytyksistä, viivästyksistä, virheistä tai puutteista, jotka
              aiheutuvat Palvelun käytöstä tai siihen liittyen. Tämä koskee myös
              Palvelun kautta saatavilla olevia tietoja ja ilmoituksia sekä
              muita mahdollisia vahinkoja.
              <br />
              <br />
              Palveluntarjoaja ei ole vastuussa vahingoista, jotka aiheutuvat
              Palvelun käyttökatkoista tai muista teknisistä ongelmista.
              Käyttäjä hyväksyy, että palvelun käytön riskit kuuluvat hänelle
              itselleen.
              <br />
              <br />
              Käyttäjä on velvollinen korvaamaan Palveluntarjoajalle ja
              kolmansille osapuolille aiheutuneet vahingot, jotka johtuvat lain,
              viranomaismääräysten, käyttöehtojen tai erityisehtojen
              rikkomisesta tai laiminlyönnistä.
            </p>

            <p className="small-text">
              <b className="paragraph-title">
                Palvelun sisältöjen oikeellisuus
              </b>
              <br />
              Verkkosivustollamme oleva materiaali ei ole kaiken kattavaa, ja se
              on tarkoitettu vain yleiseen tiedottamiseen. Jaakkocom Oy ei takaa
              tai anna mitään vakuutuksia tällä sivustolla olevien aineistojen
              tarkkuudesta, todennäköisistä tuloksista tai luotettavuudesta tai
              muusta tällaisiin aineistoihin tai tähän sivustoon linkitettyihin
              resursseihin liittyvästä käytöstä.
            </p>

            <p className="small-text">
              <b className="paragraph-title">Linkit</b>
              <br />
              Jaakkocom Oy ei ole tarkistanut kaikkia sivustoonsa linkitettyjä
              sivustoja eikä vastaa minkään linkitetyn sivuston sisällöstä.
              Minkään linkin sisällyttäminen ei tarkoita, että Jaakkocom Oy
              olisi hyväksynyt tai valvoisi kyseistä sivustoa. Tällaisten
              linkitettyjen sivustojen käyttö tapahtuu omalla vastuullasi ja
              suosittelemme, että teet omat selvityksesi kyseisten sivustojen
              soveltuvuudesta.
            </p>

            <p className="small-text">
              <b className="paragraph-title">Oikeus irtisanoa sopimus</b>
              <br />
              Voimme keskeyttää tai lopettaa oikeutesi käyttää verkkosivustoamme
              ja irtisanoa nämä käyttöehdot välittömästi ilmoituksella näiden
              käyttöehtojen rikkomisen vuoksi.
            </p>

            <p className="small-text">
              <b className="paragraph-title">Sovellettava laki</b>
              <br />
              Näihin käyttöehtoihin sovelletaan Suomen lakeja ja niitä tulkitaan
              Suomen lakien mukaisesti.
            </p>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default TermsConditions;
